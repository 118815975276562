// https://kazzkiq.github.io/balloon.css/

button[data-tooltip] {
  overflow: visible;
}

[data-tooltip] {
  position: relative;
}

[data-tooltip]:before,
[data-tooltip]:after {
  opacity: 0;
  pointer-events: none;
  transition: all 75ms ease-out 150ms;
  bottom: 100%;
  left: 50%;
  position: absolute;
  z-index: 10;
  transform: translate(-50%, 10px);
  transform-origin: top;
}

[data-tooltip]:after {
  background: rgba(17, 17, 17, 0.9);
  border-radius: $radius;
  color: #fff;
  content: attr(data-tooltip);
  font-size: 12px;
  padding: .5em 1em;
  white-space: nowrap;
  margin-bottom: 11px;
}

[data-tooltip]:before {
  background: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%280%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E') no-repeat;
  background-size: 100% auto;
  height: 6px;
  width: 18px;
  content: "";
  margin-bottom: 5px;
}

[data-tooltip]:hover:before,
[data-tooltip][data-tooltip-visible]:before,
[data-tooltip]:hover:after,
[data-tooltip][data-tooltip-visible]:after {
  @media (hover) {
    opacity: 1;
    pointer-events: auto;
  }
  transform: translate(-50%, 0);
}

[data-tooltip].font-awesome:after {
  font-family: FontAwesome;
}

[data-tooltip][data-tooltip-break]:after {
  white-space: pre;
}

[data-tooltip-pos="down"]:before,
[data-tooltip-pos="down"]:after {
  bottom: auto;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -10px);
}

[data-tooltip-pos="down"]:after {
  margin-top: 11px;
}

[data-tooltip-pos="down"]:before {
  background: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28180%2018%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E') no-repeat;
  background-size: 100% auto;
  height: 6px;
  width: 18px;
  margin-top: 5px;
  margin-bottom: 0;
}

[data-tooltip-pos="down"]:hover:before,
[data-tooltip-pos="down"][data-tooltip-visible]:before,
[data-tooltip-pos="down"]:hover:after,
[data-tooltip-pos="down"][data-tooltip-visible]:after {
  transform: translate(-50%, 0);
}

[data-tooltip-pos="left"]:before,
[data-tooltip-pos="left"]:after {
  bottom: auto;
  left: auto;
  right: 100%;
  top: 50%;
  transform: translate(10px, -50%);
}

[data-tooltip-pos="left"]:after {
  margin-right: 11px;
}

[data-tooltip-pos="left"]:before {
  background: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28-90%2018%2018%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E') no-repeat;
  background-size: 100% auto;
  height: 18px;
  width: 6px;
  margin-right: 5px;
  margin-bottom: 0;
}

[data-tooltip-pos="left"]:hover:before,
[data-tooltip-pos="left"][data-tooltip-visible]:before,
[data-tooltip-pos="left"]:hover:after,
[data-tooltip-pos="left"][data-tooltip-visible]:after {
  transform: translate(0, -50%);
}
[data-tooltip-pos="right"]:before,
[data-tooltip-pos="right"]:after {
  bottom: auto;
  left: 100%;
  top: 50%;
  transform: translate(-10px, -50%);
}
[data-tooltip-pos="right"]:after {
  margin-left: 11px;
}
[data-tooltip-pos="right"]:before {
  background: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%2890%206%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E') no-repeat;
  background-size: 100% auto;
  height: 18px;
  width: 6px;
  margin-bottom: 0;
  margin-left: 5px;
}

[data-tooltip-pos="right"]:hover:before,
[data-tooltip-pos="right"][data-tooltip-visible]:before,
[data-tooltip-pos="right"]:hover:after,
[data-tooltip-pos="right"][data-tooltip-visible]:after {
  transform: translate(0, -50%);
}

[data-tooltip-length]:after {
  white-space: normal;
}

[data-tooltip-length="small"]:after {
  width: 80px;
}

[data-tooltip-length="medium"]:after {
  width: 150px;
}

[data-tooltip-length="large"]:after {
  width: 260px;
}

[data-tooltip-length="xlarge"]:after {
  width: 90vw;
}

@media screen and (min-width: 768px) {
  [data-tooltip-length="xlarge"]:after {
    width: 380px;
  }
}

[data-tooltip-length="fit"]:after {
  width: 100%;
}

@media (max-width: 767px) {
  [data-tooltip]:before, [data-tooltip]:after {
    display: none !important;
    opacity: 0 !important;
  }
}
