.wysiwyg

    blockquote
        font-size: 1.5em
        font-style: italic
        position: relative
        padding-left: 40px
        +mq($from: tablet)
            margin-left: $core-margin-large
        &::before
            content: "\201C"
            font-family: Georgia, serif
            font-size: 3em
            position: absolute
            left: 0
            top: 20px
            color: $light-gray

    ul
        list-style: circle
    ol
        list-style: decimal
    ul, ol
        margin: 1em 0 1em 25px

    li
        margin: 12px 0
