// Paragraph
p
    margin: 1em 0


// Headers
h1
    letter-spacing: -0.5px
    color: $black
    margin-bottom: $core-margin-full
    line-height: 1
    font-size: 1.8em
    font-weight: 700

h2
    color: $black
    line-height: 1
    font-size: 1.6em
    font-weight: 600

h3
    color: $black
    line-height: 1
    font-size: 1.4em
    font-weight: 600

h4
    color: $black
    line-height: 1
    font-size: 1.3em
    font-weight: 600

h5
    color: $black
    line-height: 1
    font-size: 1.15em
    font-weight: 600

h6
    color: $black
    line-height: 1
    font-size: 1em
    font-weight: 600
