@keyframes rotate
    from
        transform: rotate(0deg)
    to
        transform: rotate(360deg)

=spinner($size, $color, $speed: 0.75s)

    display: flex
    justify-content: center
    align-items: center

    &:before
        content: ""
        width: $size
        height: $size
        overflow: hidden
        display: block
        border-right: 1px solid rgba($color, 0.25)
        border-bottom: 1px solid rgba($color, 0.25)
        border-left: 1px solid rgba($color, 0.25)
        border-top: 1px solid rgba($color, 1)
        animation: rotate $speed infinite linear
        border-radius: 50%

.spinner
    +spinner(32px, $light-gray)
    display: none
    &--active
        display: block
