.help-wrapper
    max-width: 1100px
    margin: 0 auto

.help-container
    +mq($from: tablet)
        display: flex
        //align-items: flex-start

.help-list
    width: 100%
    +mq($from: tablet)
        width: 30%
        margin-right: $core-margin-full * 2
        border: 1px solid $extra-light-gray
        border-radius: $radius-large
        padding: $core-margin-large
        max-width: 250px
        flex-shrink: 0
        align-self: flex-start
    li
        margin-bottom: $core-margin-small + $core-margin-x-small
        line-height: 130%
        color: $dark-gray
        +mq($until: tablet)
            a
                display: block
                box-shadow: 0 2px 5px rgba(0,0,0, .04), 0 2px 6px rgba(0,0,0,.03), 0 0px 1px rgba(0,0,0,0.06)
                border-radius: $radius-large
                padding: $core-margin-small+2 $core-margin-medium
        &:last-child
            margin-bottom: 0
    a
        color: $dark-gray
    &__active
        a
            color: $brand-color
            font-weight: 700

.help-list-view
    +mq($until: tablet)
        .help-content
            display: none

.help-detail-view
    +mq($until: tablet)
        h1
            display: none
        .help-list
            display: none

.help-content-wrapper
    display: flex
    flex-direction: column
    +mq($from: desktop)
        flex-direction: row

.help-content
    width: 90%
    +mq($from: desktop)
        width: 70%

.help-see-all-questions-top
    display: block
    margin-bottom: $core-margin-medium
    color: $brand-color
    &::before
        font-family: "Font Awesome 5 Light"
        content: unquote("\"#{ $fa-var-angle-left }\"")
    +mq($from: tablet)
        display: none

.help-see-all-questions
    display: block
    margin-bottom: $core-margin-full * 2
    +mq($from: tablet)
        display: none

.help-sidebar
    max-width: 100%
    margin-top: $core-margin-full
    +mq($from: desktop)
        width: 360px
        margin-left: $core-margin-full * 2
