.frontpage-wrapper
    display: flex
    flex-direction: column
    +mq($from: tablet)
        flex-direction: row

.frontpage-articles
    width: 100%
    +mq($from: tablet)
        width: 60%
    +mq($from: desktop)
        width: 70%

    .article-card
        +mq($from: tablet)
            max-width: 50%

.frontpage-diseases
    display: none
    +mq($from: tablet)
        margin-top: 0
        width: 40%
        margin-left: $core-margin-full
        display: block
        width: 100%
        margin-top: $core-margin-full * 3
    +mq($from: desktop)
        margin-top: 0
        width: 30%
        margin-left: $core-margin-full * 2

    .list-item
        display: flex
        margin-bottom: $core-margin-x-small
        color: $gray

.frontpage-diseases-sign-up
    margin-bottom: $core-margin-full * 2








.main-wrapper
    width: 73%

.sidebar-wrapper
    width: 27%
    margin-left: $core-margin-full * 2


.popular-news-list
    display: flex
    justify-content: space-between
    margin-top: $core-margin-full

    article
        width: 265px

        .article-thumbnail
            border-radius: 10px
            overflow: hidden
            margin-bottom: $core-margin-medium

        .article-title
            font-weight: 700
            font-size: 18px
            letter-spacing: -0.5px
            color: $black
            margin-bottom: $core-margin-medium

        .article-excerpt
            color: $dark-gray
            margin-bottom: $core-margin-small

        .article-date
            font-size: 12px
            color: $light-gray


.diseases-list
    li
        margin-bottom: $core-margin-medium
        line-height: 130%
    a
        color: $dark-gray
