main.article
    display: flex
    flex-direction: column

.article-cover-image
    width: 100%
    max-height: 600px
    position: relative
    overflow-y: hidden
    margin-bottom: $core-margin-medium
    +aspect-ratio(375,220)
    +mq($from: tablet)
        +aspect-ratio(1600,500)

    img
        width: 100%
        height: auto

.article-wrapper
    display: flex
    flex-direction: column
    max-width: $site-max-width
    +mq($from: tablet)
        flex-direction: row

.article-container
    display: flex
    flex-direction: column
    +mq($from: desktop)
        flex-direction: row

.article-sharing
    order: 2
    margin-top: $core-margin-full
    border: 1px solid $extra-lighter-gray
    border-radius: $radius-large
    position: relative
    z-index: 2
    +mq($from: desktop)
        border: none
        order: 0
        width: 80px
        display: flex
        justify-content: center
        flex-shrink: 0
        margin-top: $core-margin-full * 3
    +mq($until: desktop)
        &__title
            background: $ultra-light-gray
            border-radius: $radius-large 0 0 $radius-large

    ul
        display: flex
        +mq($from: desktop)
            flex-direction: column

        li
            padding: $core-margin-small 0
            width: 25%
            font-size: 14px
            color: $lighter-gray
            display: flex
            align-items: center
            justify-content: center
            +mq($from: desktop)
                width: 100%
                padding: 0
                margin-bottom: $core-margin-large

            a
                display: block

        i
            font-size: 28px
            +mq($from: desktop)
                font-size: 32px

.article-main
    background: #ffffff
    position: relative
    z-index: 1
    color: $dark-gray
    order: 1
    z-index: 1
    margin-top: $core-margin-medium
    padding: 0 $core-margin-medium
    +mq($from: desktop)
        margin-top: -($core-margin-full * 2)
        border-radius: $radius-large
        padding: $core-margin-full ($core-margin-full +$core-margin-small)

.article-sidebar
    max-width: 100%
    flex-shrink: 0
    position: relative
    z-index: 2
    margin-top: $core-margin-full * 2
    order: 3
    +mq($from: tablet)
        width: 320px
        margin-left: $core-margin-full
    +mq($from: desktop)
        width: 360px
        margin-left: $core-margin-full * 2

.article-title
    margin-bottom: $core-margin-full * 1.5
    font-size: 34px
    line-height: 110%
    margin-top: $core-margin-small
    overflow-wrap: break-word
    word-wrap: break-word
    word-break: break-word
    +mq($from: tablet)
        margin-top: 0
        font-size: 38px
    +mq($from: desktop)
        font-size: 42px

.article-by-line-wrapper
    margin-bottom: $core-margin-full
    margin-top: -$core-margin-full

.article-by-line
    margin-bottom: 0
    font-size: 14px
    color: $light-gray
    display: inline
    +mq($from: tablet)
        font-size: 13px

.article-read-time
    font-size: 13px
    color: $lighter-gray
    display: inline

    &::before
        content: "⋅"

.article-lead
    margin-top: $core-margin-medium
    margin-bottom: $core-margin-full + $core-margin-large
    font-size: 18px
    font-weight: 600
    line-height: 130%
    +mq($from: tablet)
        font-size: 20px

.article-button
    margin-top: -10px
    margin-bottom: 30px

.article-content
    font-size: 16px
    line-height: 150%
    color: $darker-gray
    word-break: break-word

    ul,ol
        margin: 1em 30px
        list-style: disc

        ul,ol
            margin: 0 30px

.article-last-updated
    color: $gray
    font-size: 13px

.article-sources
    margin-top: $core-margin-full * 2
    color: $light-gray
    font-size: 13px
    word-break: break-all

h2.product-cards-title
    font-size: 16px
    color: $gray

.article-view-all-articles
    max-width: 340px
    margin: 0 auto

.iframe-article
    .article-main
        padding: 36px

    h1
        font-size: 24px

    h2, h3
        font-size: 16px

    p, li
        font-size: 13px
        line-height: 125%

.search-title
    font-size: 20px
    font-weight: 700
    color: #f90248
    border-bottom: 2px solid #f90248
    padding-bottom: 10px
    display: inline-block
    margin-bottom: 12px

.question-line
    margin: 24px 0
    font-size: 20px
    font-weight: 600
    line-height: 120%
