@import "variables";

@font-face {
    font-family: "Font Awesome 5 Brands";
    font-style: normal;
    font-weight: 900;
    src: url("../#{$fa-font-path}/#{$fa-filename}-brands-900.woff2")
        format("woff2");
}

.fab {
    font-family: "Font Awesome 5 Brands";
    font-weight: 900;
}
