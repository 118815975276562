@import "variables";

@font-face {
    font-family: "Font Awesome 5 Light";
    font-style: normal;
    font-weight: 300;
    src: url("../#{$fa-font-path}/#{$fa-filename}-light-300.woff2") format("woff2");
}

.fal {
    font-family: "Font Awesome 5 Light";
    font-weight: 300;
}
