.content-404
    margin: $core-margin-full auto
    max-width: 480px
    padding: $core-margin-full $core-margin-full
    display: flex
    align-items: center
    +mq($from: tablet)
        box-shadow: 0 15px 35px rgba(0,0,0, .08), 0 5px 15px rgba(0,0,0,.06), 0 0px 3px rgba(0,0,0,0.06)
        border-radius: $radius-large
        margin: $core-margin-full * 2 auto

    &__header
        font-size: 32px
        font-weight: 600
        margin-bottom: $core-margin-full

    &__content
        font-size: 18px
        color: $dark-gray
        margin-bottom: $core-margin-full

    &__links-title
        font-size: 12px
        color: $gray
        font-weight: 700

    &__image
        width: 125px
        flex-shrink: 0
        margin-left: $core-margin-full

    ul
        a
            font-weight: 600
            font-size: 16px
