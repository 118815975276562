.pagination
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: $core-margin-full * 2
    color: $gray
    // box-shadow: 0 1px 1px rgba(0,0,0,.05)


    li
        border: 1px solid $extra-light-gray
        font-weight: 700
        margin-left: -1px
        &:first-child
            border-top-left-radius: $radius
            border-bottom-left-radius: $radius
        &:last-child
            border-top-right-radius: $radius
            border-bottom-right-radius: $radius

        .page-link
            display: flex
            height: 100%
            width: 100%
            align-items: center
            justify-content: center
            padding: 7px 13px
        a:hover
            background: $ultra-light-gray
        &.active
            color: #ffffff
            background: $brand-color
            border-color: $brand-color
            z-index: 2
            span
                padding: 7px 13px
        &.disabled
            //background: $extra-light-gray
            color: $light-gray
            background-color: $ultra-light-gray
            span
                padding: 7px 13px
