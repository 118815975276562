.sign-up-block
    display: none
    +mq($from: tablet)
        display: block
        width: 100%
        box-shadow: 0 15px 35px rgba(0,0,0, .08), 0 5px 15px rgba(0,0,0,.06), 0 0px 3px rgba(0,0,0,0.06)
        border-radius: $radius
        padding: $core-margin-full $core-margin-large
        &__bullet
            color: $brand-color
            font-weight: 600
            span
                font-weight: 400

        &__description
            margin: $core-margin-small 0 $core-margin-medium
            color: $dark-gray
            font-size: 13px
            line-height: 135%
