.content-wrapper
    margin: 0
    max-width: 100%

.content-container
    @extend %site-max-width

.content-block
    margin: 0 auto
    padding: 0 $core-margin-medium
    position: relative
    +mq($from: desktop)
        padding: 0
    &--push
        margin-top: $core-margin-full
        margin-bottom: $core-margin-full * 2
        +mq($from: tablet)
            margin-top: $core-margin-full * 2
            margin-bottom: $core-margin-full * 3
    &--tight
        max-width: 800px
        margin-bottom: $core-margin-full * 2
        +mq($from: tablet)
            margin-top: -$core-margin-full * 2
            margin-bottom: 0
            padding: $core-margin-full ($core-margin-full +$core-margin-small)

.content-description
    color: $dark-gray
    border-top: 1px solid #eee
    padding-top: $core-margin-full
