.become-a-part-wrapper
    margin: ($core-margin-full * 3) auto 0
    color: $gray
    padding: ($core-margin-large * 2) 0
    border-top: 1px solid #eee
    background-image: linear-gradient(#f9f9f8, #ffffff 20%)
    +mq($from: tablet)
        padding: ($core-margin-full * 2) 0

.become-a-part-container
    display: flex
    max-width: 900px
    margin: 0 auto
    padding: 0 $core-margin-full
    flex-direction: column
    +mq($from: tablet)
        flex-direction: row
    +mq($from: desktop)
        padding: 0

.become-a-part-content
    width: 100%

    +mq($from: tablet)
        width: 65%
        margin-right: $core-margin-full * 2
    h2
        margin-bottom: $core-margin-medium
        +mq($from: tablet)
            margin-bottom: 0
    p
        display: none
        +mq($from: tablet)
            display: block

    .button
        margin: $core-margin-full 0 $core-margin-medium
        +mq($from: tablet)
            margin: 0


.become-a-part-sign-up
    display: block
    font-size: 12px
    line-height: 130%
    p
        +mq($until: tablet)
            margin-bottom: 0
    b
        color: $dark-gray
    +mq($from: tablet)
        font-size: 14px
        display: flex
        align-items: center
        width: 35%
        box-shadow: 0 15px 35px rgba(0,0,0, .08), 0 5px 15px rgba(0,0,0,.06), 0 0px 3px rgba(0,0,0,0.06)
        border-radius: $radius
        padding: 0 $core-margin-full
