.top-wrapper
    display: none
    +mq($from: desktop)
        display: block
        background: #f6f9fc
        height: 30px
        font-size: 13px
        padding: 0 $core-margin-large

.top-container
    height: 100%
    display: flex
    align-items: center
    justify-content: space-between
    @extend %site-max-width

.top-trending
    display: flex
    color: $dark-gray
    &__header
        font-weight: 700
        margin-right: $core-margin-small

.top-navigation
    ul
        display: flex
        align-items: center
    li
        margin-left: $core-margin-large
    color: $dark-gray
    a
        color: $dark-gray
        &:hover
            color: $brand-color

.header-wrapper
    height: 52px
    background: #ffffff
    border-bottom: 1px solid #e5e5e5
    padding: 0 $core-margin-large
    position: sticky
    top: 0
    z-index: map-get($z-index, header)
    box-shadow: 0 1px 2px rgba(0,0,0,.05)
    +mq($from: tablet)
        height: 70px

.header-container
    height: 100%
    display: flex
    align-items: center
    justify-content: space-between
    overflow: hidden
    @extend %site-max-width

.logo
    height: 23px
    +mq($from: tablet)
        height: 26px
    img
        height: 100%
        width: auto

.search-wrapper
    display: none
    +mq($from: desktop)
        position: relative
        display: block
    input
        border-radius: $radius
        width: 300px
        flex-shrink: 0
        box-shadow: 0 1px 1px rgba(0,0,0, 0.05)
        font-size: 13px
        +mq($from: wide)
            width: 340px
            font-size: 14px

.navigation
    +mq($until: tablet)
        visibility: hidden
        opacity: 0
        transition: all 0.1s ease-in
        position: fixed
        -webkit-overflow-scrolling: touch
        &--active
            visibility: visible
            opacity: 1
            right: 0
            transition: all 0.1s ease-out
            transform: translateY(0)

        height: 100vh
        width: 75vw
        right: -30vw
        background: #ffffff
        top: 52px
        border-left: 1px solid #e5e5e5
        overflow: scroll
        z-index: 1
        ul
            display: block
            padding: $core-margin-full $core-margin-full
            &:last-child
                padding-top: $core-margin-large
                border-top: 1px solid #eeeeee
        li
            font-weight: 600
            font-size: 20px
        a
            display: block
            padding: $core-margin-medium 0

    +mq($from: tablet)
        display: block
        margin: 0 $core-margin-full
        ul
            display: flex
        li
            font-size: 15px
            font-weight: 600
        a
            display: flex
            padding: $core-margin-medium $core-margin-large
            border-radius: $radius
            color: #191919
            transition: background 0.05s ease-in
            &:hover
                background: #f6f9fc

    &__count
        margin-left: $core-margin-medium
        border-radius: 50%
        width: 18px
        height: 18px
        background-image: linear-gradient(to bottom left, #ff021e, #f2017e)
        color: #ffffff
        display: flex
        align-items: center
        justify-content: center
        padding: 11px
        top: 0
        position: relative
        font-size: 18px
        +mq($from: tablet)
            margin-left: $core-margin-x-small

.hamburger-menu
    font-size: 22px
    padding: $core-margin-medium $core-margin-small $core-margin-medium $core-margin-large
    +mq($from: tablet)
        display: none

.visible-mobile
    display: block
    +mq($from: tablet)
        display: none
        visibility: hidden
        opacity: 0
        transform: translateY(-99999px)
