.banner
    border-radius: $radius
    display: flex
    flex-direction: column
    justify-content: space-between
    position: relative
    margin-bottom: $core-margin-full
    padding: $core-margin-large $core-margin-large $core-margin-large
    +mq($from: desktop)
        flex-direction: row
        align-items: center
        padding: $core-margin-medium $core-margin-large $core-margin-medium

    &--patient-enrollment
        background-image: linear-gradient(to bottom right, lighten(#ff021e, 20), lighten(#f2017e, 20))
        color: #ffffff

    &__header
        font-size: 20px
        font-weight: 600
        margin-bottom: $core-margin-medium
        +mq($from: desktop)
            margin-bottom: 0
            border-right: 1px solid rgba(255,255,255, 0.5)
            padding-right: $core-margin-full
            margin-right: $core-margin-full
            span
                display: block
        +mq($until: tablet)
            i
                display: none

    &__content
        flex-grow: 1
        line-height: 125%
        a
            color: #ffffff

    &__title
        display: none
        margin-bottom: -6px
        +mq($from: desktop)
            display: block
            opacity: 0.75
            font-size: 13px

    &__text
        font-weight: 600
        margin-bottom: $core-margin-large

        +mq($from: desktop)
            margin-bottom: 0
            margin-top: 6px
            &:before
                content: "·"
                margin-right: 6px

    &__button
        width: 100%
        flex-shrink: 0
        +mq($from: desktop)
            width: auto
        .button
            height: 36px
