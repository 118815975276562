// Settings
$radius: 3px
$radius-large: 10px
$shadow: rgba(black, .15) 0 1px 1px
$border: 1px solid #e2e5e8

$black: #14171a
$darker-gray: lighten($black, 20)
$dark-gray: lighten($black, 30)
$gray: lighten($black, 50)
$light-gray: lighten($black, 60)
$lighter-gray: lighten($black, 75)
$extra-light-gray: lighten($black, 82)
$extra-lighter-gray: lighten($black, 85)
$ultra-light-gray: lighten($black, 89)

// Used in image-url
$http_image_url_production: "https://static.coolshop.com/_origami/images/"


// Fonts
$font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"


*
    box-sizing: border-box

html, body
    font-size: 14px
    line-height: 20px
    text-rendering: optimizeLegibility
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
