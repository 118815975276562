/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */

.#{$fa-css-prefix}-500px:before { content: unquote("\"#{ $fa-var-500px }\""); }
.#{$fa-css-prefix}-accessible-icon:before { content: unquote("\"#{ $fa-var-accessible-icon }\""); }
.#{$fa-css-prefix}-accusoft:before { content: unquote("\"#{ $fa-var-accusoft }\""); }
.#{$fa-css-prefix}-address-book:before { content: unquote("\"#{ $fa-var-address-book }\""); }
.#{$fa-css-prefix}-address-card:before { content: unquote("\"#{ $fa-var-address-card }\""); }
.#{$fa-css-prefix}-adjust:before { content: unquote("\"#{ $fa-var-adjust }\""); }
.#{$fa-css-prefix}-adn:before { content: unquote("\"#{ $fa-var-adn }\""); }
.#{$fa-css-prefix}-adversal:before { content: unquote("\"#{ $fa-var-adversal }\""); }
.#{$fa-css-prefix}-affiliatetheme:before { content: unquote("\"#{ $fa-var-affiliatetheme }\""); }
.#{$fa-css-prefix}-alarm-clock:before { content: unquote("\"#{ $fa-var-alarm-clock }\""); }
.#{$fa-css-prefix}-algolia:before { content: unquote("\"#{ $fa-var-algolia }\""); }
.#{$fa-css-prefix}-align-center:before { content: unquote("\"#{ $fa-var-align-center }\""); }
.#{$fa-css-prefix}-align-justify:before { content: unquote("\"#{ $fa-var-align-justify }\""); }
.#{$fa-css-prefix}-align-left:before { content: unquote("\"#{ $fa-var-align-left }\""); }
.#{$fa-css-prefix}-align-right:before { content: unquote("\"#{ $fa-var-align-right }\""); }
.#{$fa-css-prefix}-amazon:before { content: unquote("\"#{ $fa-var-amazon }\""); }
.#{$fa-css-prefix}-ambulance:before { content: unquote("\"#{ $fa-var-ambulance }\""); }
.#{$fa-css-prefix}-american-sign-language-interpreting:before { content: unquote("\"#{ $fa-var-american-sign-language-interpreting }\""); }
.#{$fa-css-prefix}-amilia:before { content: unquote("\"#{ $fa-var-amilia }\""); }
.#{$fa-css-prefix}-anchor:before { content: unquote("\"#{ $fa-var-anchor }\""); }
.#{$fa-css-prefix}-android:before { content: unquote("\"#{ $fa-var-android }\""); }
.#{$fa-css-prefix}-angellist:before { content: unquote("\"#{ $fa-var-angellist }\""); }
.#{$fa-css-prefix}-angle-double-down:before { content: unquote("\"#{ $fa-var-angle-double-down }\""); }
.#{$fa-css-prefix}-angle-double-left:before { content: unquote("\"#{ $fa-var-angle-double-left }\""); }
.#{$fa-css-prefix}-angle-double-right:before { content: unquote("\"#{ $fa-var-angle-double-right }\""); }
.#{$fa-css-prefix}-angle-double-up:before { content: unquote("\"#{ $fa-var-angle-double-up }\""); }
.#{$fa-css-prefix}-angle-down:before { content: unquote("\"#{ $fa-var-angle-down }\""); }
.#{$fa-css-prefix}-angle-left:before { content: unquote("\"#{ $fa-var-angle-left }\""); }
.#{$fa-css-prefix}-angle-right:before { content: unquote("\"#{ $fa-var-angle-right }\""); }
.#{$fa-css-prefix}-angle-up:before { content: unquote("\"#{ $fa-var-angle-up }\""); }
.#{$fa-css-prefix}-angrycreative:before { content: unquote("\"#{ $fa-var-angrycreative }\""); }
.#{$fa-css-prefix}-app-store:before { content: unquote("\"#{ $fa-var-app-store }\""); }
.#{$fa-css-prefix}-app-store-ios:before { content: unquote("\"#{ $fa-var-app-store-ios }\""); }
.#{$fa-css-prefix}-apper:before { content: unquote("\"#{ $fa-var-apper }\""); }
.#{$fa-css-prefix}-apple:before { content: unquote("\"#{ $fa-var-apple }\""); }
.#{$fa-css-prefix}-apple-pay:before { content: unquote("\"#{ $fa-var-apple-pay }\""); }
.#{$fa-css-prefix}-archive:before { content: unquote("\"#{ $fa-var-archive }\""); }
.#{$fa-css-prefix}-arrow-alt-circle-down:before { content: unquote("\"#{ $fa-var-arrow-alt-circle-down }\""); }
.#{$fa-css-prefix}-arrow-alt-circle-left:before { content: unquote("\"#{ $fa-var-arrow-alt-circle-left }\""); }
.#{$fa-css-prefix}-arrow-alt-circle-right:before { content: unquote("\"#{ $fa-var-arrow-alt-circle-right }\""); }
.#{$fa-css-prefix}-arrow-alt-circle-up:before { content: unquote("\"#{ $fa-var-arrow-alt-circle-up }\""); }
.#{$fa-css-prefix}-arrow-alt-down:before { content: unquote("\"#{ $fa-var-arrow-alt-down }\""); }
.#{$fa-css-prefix}-arrow-alt-from-bottom:before { content: unquote("\"#{ $fa-var-arrow-alt-from-bottom }\""); }
.#{$fa-css-prefix}-arrow-alt-from-left:before { content: unquote("\"#{ $fa-var-arrow-alt-from-left }\""); }
.#{$fa-css-prefix}-arrow-alt-from-right:before { content: unquote("\"#{ $fa-var-arrow-alt-from-right }\""); }
.#{$fa-css-prefix}-arrow-alt-from-top:before { content: unquote("\"#{ $fa-var-arrow-alt-from-top }\""); }
.#{$fa-css-prefix}-arrow-alt-left:before { content: unquote("\"#{ $fa-var-arrow-alt-left }\""); }
.#{$fa-css-prefix}-arrow-alt-right:before { content: unquote("\"#{ $fa-var-arrow-alt-right }\""); }
.#{$fa-css-prefix}-arrow-alt-square-down:before { content: unquote("\"#{ $fa-var-arrow-alt-square-down }\""); }
.#{$fa-css-prefix}-arrow-alt-square-left:before { content: unquote("\"#{ $fa-var-arrow-alt-square-left }\""); }
.#{$fa-css-prefix}-arrow-alt-square-right:before { content: unquote("\"#{ $fa-var-arrow-alt-square-right }\""); }
.#{$fa-css-prefix}-arrow-alt-square-up:before { content: unquote("\"#{ $fa-var-arrow-alt-square-up }\""); }
.#{$fa-css-prefix}-arrow-alt-to-bottom:before { content: unquote("\"#{ $fa-var-arrow-alt-to-bottom }\""); }
.#{$fa-css-prefix}-arrow-alt-to-left:before { content: unquote("\"#{ $fa-var-arrow-alt-to-left }\""); }
.#{$fa-css-prefix}-arrow-alt-to-right:before { content: unquote("\"#{ $fa-var-arrow-alt-to-right }\""); }
.#{$fa-css-prefix}-arrow-alt-to-top:before { content: unquote("\"#{ $fa-var-arrow-alt-to-top }\""); }
.#{$fa-css-prefix}-arrow-alt-up:before { content: unquote("\"#{ $fa-var-arrow-alt-up }\""); }
.#{$fa-css-prefix}-arrow-circle-down:before { content: unquote("\"#{ $fa-var-arrow-circle-down }\""); }
.#{$fa-css-prefix}-arrow-circle-left:before { content: unquote("\"#{ $fa-var-arrow-circle-left }\""); }
.#{$fa-css-prefix}-arrow-circle-right:before { content: unquote("\"#{ $fa-var-arrow-circle-right }\""); }
.#{$fa-css-prefix}-arrow-circle-up:before { content: unquote("\"#{ $fa-var-arrow-circle-up }\""); }
.#{$fa-css-prefix}-arrow-down:before { content: unquote("\"#{ $fa-var-arrow-down }\""); }
.#{$fa-css-prefix}-arrow-from-bottom:before { content: unquote("\"#{ $fa-var-arrow-from-bottom }\""); }
.#{$fa-css-prefix}-arrow-from-left:before { content: unquote("\"#{ $fa-var-arrow-from-left }\""); }
.#{$fa-css-prefix}-arrow-from-right:before { content: unquote("\"#{ $fa-var-arrow-from-right }\""); }
.#{$fa-css-prefix}-arrow-from-top:before { content: unquote("\"#{ $fa-var-arrow-from-top }\""); }
.#{$fa-css-prefix}-arrow-left:before { content: unquote("\"#{ $fa-var-arrow-left }\""); }
.#{$fa-css-prefix}-arrow-right:before { content: unquote("\"#{ $fa-var-arrow-right }\""); }
.#{$fa-css-prefix}-arrow-square-down:before { content: unquote("\"#{ $fa-var-arrow-square-down }\""); }
.#{$fa-css-prefix}-arrow-square-left:before { content: unquote("\"#{ $fa-var-arrow-square-left }\""); }
.#{$fa-css-prefix}-arrow-square-right:before { content: unquote("\"#{ $fa-var-arrow-square-right }\""); }
.#{$fa-css-prefix}-arrow-square-up:before { content: unquote("\"#{ $fa-var-arrow-square-up }\""); }
.#{$fa-css-prefix}-arrow-to-bottom:before { content: unquote("\"#{ $fa-var-arrow-to-bottom }\""); }
.#{$fa-css-prefix}-arrow-to-left:before { content: unquote("\"#{ $fa-var-arrow-to-left }\""); }
.#{$fa-css-prefix}-arrow-to-right:before { content: unquote("\"#{ $fa-var-arrow-to-right }\""); }
.#{$fa-css-prefix}-arrow-to-top:before { content: unquote("\"#{ $fa-var-arrow-to-top }\""); }
.#{$fa-css-prefix}-arrow-up:before { content: unquote("\"#{ $fa-var-arrow-up }\""); }
.#{$fa-css-prefix}-arrows:before { content: unquote("\"#{ $fa-var-arrows }\""); }
.#{$fa-css-prefix}-arrows-alt:before { content: unquote("\"#{ $fa-var-arrows-alt }\""); }
.#{$fa-css-prefix}-arrows-alt-h:before { content: unquote("\"#{ $fa-var-arrows-alt-h }\""); }
.#{$fa-css-prefix}-arrows-alt-v:before { content: unquote("\"#{ $fa-var-arrows-alt-v }\""); }
.#{$fa-css-prefix}-arrows-h:before { content: unquote("\"#{ $fa-var-arrows-h }\""); }
.#{$fa-css-prefix}-arrows-v:before { content: unquote("\"#{ $fa-var-arrows-v }\""); }
.#{$fa-css-prefix}-assistive-listening-systems:before { content: unquote("\"#{ $fa-var-assistive-listening-systems }\""); }
.#{$fa-css-prefix}-asterisk:before { content: unquote("\"#{ $fa-var-asterisk }\""); }
.#{$fa-css-prefix}-asymmetrik:before { content: unquote("\"#{ $fa-var-asymmetrik }\""); }
.#{$fa-css-prefix}-at:before { content: unquote("\"#{ $fa-var-at }\""); }
.#{$fa-css-prefix}-audible:before { content: unquote("\"#{ $fa-var-audible }\""); }
.#{$fa-css-prefix}-audio-description:before { content: unquote("\"#{ $fa-var-audio-description }\""); }
.#{$fa-css-prefix}-autoprefixer:before { content: unquote("\"#{ $fa-var-autoprefixer }\""); }
.#{$fa-css-prefix}-avianex:before { content: unquote("\"#{ $fa-var-avianex }\""); }
.#{$fa-css-prefix}-aws:before { content: unquote("\"#{ $fa-var-aws }\""); }
.#{$fa-css-prefix}-backward:before { content: unquote("\"#{ $fa-var-backward }\""); }
.#{$fa-css-prefix}-badge:before { content: unquote("\"#{ $fa-var-badge }\""); }
.#{$fa-css-prefix}-badge-check:before { content: unquote("\"#{ $fa-var-badge-check }\""); }
.#{$fa-css-prefix}-balance-scale:before { content: unquote("\"#{ $fa-var-balance-scale }\""); }
.#{$fa-css-prefix}-ban:before { content: unquote("\"#{ $fa-var-ban }\""); }
.#{$fa-css-prefix}-bandcamp:before { content: unquote("\"#{ $fa-var-bandcamp }\""); }
.#{$fa-css-prefix}-barcode:before { content: unquote("\"#{ $fa-var-barcode }\""); }
.#{$fa-css-prefix}-bars:before { content: unquote("\"#{ $fa-var-bars }\""); }
.#{$fa-css-prefix}-bath:before { content: unquote("\"#{ $fa-var-bath }\""); }
.#{$fa-css-prefix}-battery-bolt:before { content: unquote("\"#{ $fa-var-battery-bolt }\""); }
.#{$fa-css-prefix}-battery-empty:before { content: unquote("\"#{ $fa-var-battery-empty }\""); }
.#{$fa-css-prefix}-battery-full:before { content: unquote("\"#{ $fa-var-battery-full }\""); }
.#{$fa-css-prefix}-battery-half:before { content: unquote("\"#{ $fa-var-battery-half }\""); }
.#{$fa-css-prefix}-battery-quarter:before { content: unquote("\"#{ $fa-var-battery-quarter }\""); }
.#{$fa-css-prefix}-battery-slash:before { content: unquote("\"#{ $fa-var-battery-slash }\""); }
.#{$fa-css-prefix}-battery-three-quarters:before { content: unquote("\"#{ $fa-var-battery-three-quarters }\""); }
.#{$fa-css-prefix}-bed:before { content: unquote("\"#{ $fa-var-bed }\""); }
.#{$fa-css-prefix}-beer:before { content: unquote("\"#{ $fa-var-beer }\""); }
.#{$fa-css-prefix}-behance:before { content: unquote("\"#{ $fa-var-behance }\""); }
.#{$fa-css-prefix}-behance-square:before { content: unquote("\"#{ $fa-var-behance-square }\""); }
.#{$fa-css-prefix}-bell:before { content: unquote("\"#{ $fa-var-bell }\""); }
.#{$fa-css-prefix}-bell-slash:before { content: unquote("\"#{ $fa-var-bell-slash }\""); }
.#{$fa-css-prefix}-bicycle:before { content: unquote("\"#{ $fa-var-bicycle }\""); }
.#{$fa-css-prefix}-bimobject:before { content: unquote("\"#{ $fa-var-bimobject }\""); }
.#{$fa-css-prefix}-binoculars:before { content: unquote("\"#{ $fa-var-binoculars }\""); }
.#{$fa-css-prefix}-birthday-cake:before { content: unquote("\"#{ $fa-var-birthday-cake }\""); }
.#{$fa-css-prefix}-bitbucket:before { content: unquote("\"#{ $fa-var-bitbucket }\""); }
.#{$fa-css-prefix}-bitcoin:before { content: unquote("\"#{ $fa-var-bitcoin }\""); }
.#{$fa-css-prefix}-bity:before { content: unquote("\"#{ $fa-var-bity }\""); }
.#{$fa-css-prefix}-black-tie:before { content: unquote("\"#{ $fa-var-black-tie }\""); }
.#{$fa-css-prefix}-blackberry:before { content: unquote("\"#{ $fa-var-blackberry }\""); }
.#{$fa-css-prefix}-blind:before { content: unquote("\"#{ $fa-var-blind }\""); }
.#{$fa-css-prefix}-blogger:before { content: unquote("\"#{ $fa-var-blogger }\""); }
.#{$fa-css-prefix}-blogger-b:before { content: unquote("\"#{ $fa-var-blogger-b }\""); }
.#{$fa-css-prefix}-bluetooth:before { content: unquote("\"#{ $fa-var-bluetooth }\""); }
.#{$fa-css-prefix}-bluetooth-b:before { content: unquote("\"#{ $fa-var-bluetooth-b }\""); }
.#{$fa-css-prefix}-bold:before { content: unquote("\"#{ $fa-var-bold }\""); }
.#{$fa-css-prefix}-bolt:before { content: unquote("\"#{ $fa-var-bolt }\""); }
.#{$fa-css-prefix}-bomb:before { content: unquote("\"#{ $fa-var-bomb }\""); }
.#{$fa-css-prefix}-book:before { content: unquote("\"#{ $fa-var-book }\""); }
.#{$fa-css-prefix}-bookmark:before { content: unquote("\"#{ $fa-var-bookmark }\""); }
.#{$fa-css-prefix}-braille:before { content: unquote("\"#{ $fa-var-braille }\""); }
.#{$fa-css-prefix}-briefcase:before { content: unquote("\"#{ $fa-var-briefcase }\""); }
.#{$fa-css-prefix}-browser:before { content: unquote("\"#{ $fa-var-browser }\""); }
.#{$fa-css-prefix}-btc:before { content: unquote("\"#{ $fa-var-btc }\""); }
.#{$fa-css-prefix}-bug:before { content: unquote("\"#{ $fa-var-bug }\""); }
.#{$fa-css-prefix}-building:before { content: unquote("\"#{ $fa-var-building }\""); }
.#{$fa-css-prefix}-bullhorn:before { content: unquote("\"#{ $fa-var-bullhorn }\""); }
.#{$fa-css-prefix}-bullseye:before { content: unquote("\"#{ $fa-var-bullseye }\""); }
.#{$fa-css-prefix}-buromobelexperte:before { content: unquote("\"#{ $fa-var-buromobelexperte }\""); }
.#{$fa-css-prefix}-bus:before { content: unquote("\"#{ $fa-var-bus }\""); }
.#{$fa-css-prefix}-buysellads:before { content: unquote("\"#{ $fa-var-buysellads }\""); }
.#{$fa-css-prefix}-calculator:before { content: unquote("\"#{ $fa-var-calculator }\""); }
.#{$fa-css-prefix}-calendar:before { content: unquote("\"#{ $fa-var-calendar }\""); }
.#{$fa-css-prefix}-calendar-alt:before { content: unquote("\"#{ $fa-var-calendar-alt }\""); }
.#{$fa-css-prefix}-calendar-check:before { content: unquote("\"#{ $fa-var-calendar-check }\""); }
.#{$fa-css-prefix}-calendar-edit:before { content: unquote("\"#{ $fa-var-calendar-edit }\""); }
.#{$fa-css-prefix}-calendar-exclamation:before { content: unquote("\"#{ $fa-var-calendar-exclamation }\""); }
.#{$fa-css-prefix}-calendar-minus:before { content: unquote("\"#{ $fa-var-calendar-minus }\""); }
.#{$fa-css-prefix}-calendar-plus:before { content: unquote("\"#{ $fa-var-calendar-plus }\""); }
.#{$fa-css-prefix}-calendar-times:before { content: unquote("\"#{ $fa-var-calendar-times }\""); }
.#{$fa-css-prefix}-camera:before { content: unquote("\"#{ $fa-var-camera }\""); }
.#{$fa-css-prefix}-camera-alt:before { content: unquote("\"#{ $fa-var-camera-alt }\""); }
.#{$fa-css-prefix}-camera-retro:before { content: unquote("\"#{ $fa-var-camera-retro }\""); }
.#{$fa-css-prefix}-car:before { content: unquote("\"#{ $fa-var-car }\""); }
.#{$fa-css-prefix}-caret-circle-down:before { content: unquote("\"#{ $fa-var-caret-circle-down }\""); }
.#{$fa-css-prefix}-caret-circle-left:before { content: unquote("\"#{ $fa-var-caret-circle-left }\""); }
.#{$fa-css-prefix}-caret-circle-right:before { content: unquote("\"#{ $fa-var-caret-circle-right }\""); }
.#{$fa-css-prefix}-caret-circle-up:before { content: unquote("\"#{ $fa-var-caret-circle-up }\""); }
.#{$fa-css-prefix}-caret-down:before { content: unquote("\"#{ $fa-var-caret-down }\""); }
.#{$fa-css-prefix}-caret-left:before { content: unquote("\"#{ $fa-var-caret-left }\""); }
.#{$fa-css-prefix}-caret-right:before { content: unquote("\"#{ $fa-var-caret-right }\""); }
.#{$fa-css-prefix}-caret-square-down:before { content: unquote("\"#{ $fa-var-caret-square-down }\""); }
.#{$fa-css-prefix}-caret-square-left:before { content: unquote("\"#{ $fa-var-caret-square-left }\""); }
.#{$fa-css-prefix}-caret-square-right:before { content: unquote("\"#{ $fa-var-caret-square-right }\""); }
.#{$fa-css-prefix}-caret-square-up:before { content: unquote("\"#{ $fa-var-caret-square-up }\""); }
.#{$fa-css-prefix}-caret-up:before { content: unquote("\"#{ $fa-var-caret-up }\""); }
.#{$fa-css-prefix}-cart-arrow-down:before { content: unquote("\"#{ $fa-var-cart-arrow-down }\""); }
.#{$fa-css-prefix}-cart-plus:before { content: unquote("\"#{ $fa-var-cart-plus }\""); }
.#{$fa-css-prefix}-cc-amex:before { content: unquote("\"#{ $fa-var-cc-amex }\""); }
.#{$fa-css-prefix}-cc-apple-pay:before { content: unquote("\"#{ $fa-var-cc-apple-pay }\""); }
.#{$fa-css-prefix}-cc-diners-club:before { content: unquote("\"#{ $fa-var-cc-diners-club }\""); }
.#{$fa-css-prefix}-cc-discover:before { content: unquote("\"#{ $fa-var-cc-discover }\""); }
.#{$fa-css-prefix}-cc-jcb:before { content: unquote("\"#{ $fa-var-cc-jcb }\""); }
.#{$fa-css-prefix}-cc-mastercard:before { content: unquote("\"#{ $fa-var-cc-mastercard }\""); }
.#{$fa-css-prefix}-cc-paypal:before { content: unquote("\"#{ $fa-var-cc-paypal }\""); }
.#{$fa-css-prefix}-cc-stripe:before { content: unquote("\"#{ $fa-var-cc-stripe }\""); }
.#{$fa-css-prefix}-cc-visa:before { content: unquote("\"#{ $fa-var-cc-visa }\""); }
.#{$fa-css-prefix}-centercode:before { content: unquote("\"#{ $fa-var-centercode }\""); }
.#{$fa-css-prefix}-certificate:before { content: unquote("\"#{ $fa-var-certificate }\""); }
.#{$fa-css-prefix}-chart-area:before { content: unquote("\"#{ $fa-var-chart-area }\""); }
.#{$fa-css-prefix}-chart-bar:before { content: unquote("\"#{ $fa-var-chart-bar }\""); }
.#{$fa-css-prefix}-chart-line:before { content: unquote("\"#{ $fa-var-chart-line }\""); }
.#{$fa-css-prefix}-chart-pie:before { content: unquote("\"#{ $fa-var-chart-pie }\""); }
.#{$fa-css-prefix}-check:before { content: unquote("\"#{ $fa-var-check }\""); }
.#{$fa-css-prefix}-check-circle:before { content: unquote("\"#{ $fa-var-check-circle }\""); }
.#{$fa-css-prefix}-check-square:before { content: unquote("\"#{ $fa-var-check-square }\""); }
.#{$fa-css-prefix}-chevron-circle-down:before { content: unquote("\"#{ $fa-var-chevron-circle-down }\""); }
.#{$fa-css-prefix}-chevron-circle-left:before { content: unquote("\"#{ $fa-var-chevron-circle-left }\""); }
.#{$fa-css-prefix}-chevron-circle-right:before { content: unquote("\"#{ $fa-var-chevron-circle-right }\""); }
.#{$fa-css-prefix}-chevron-circle-up:before { content: unquote("\"#{ $fa-var-chevron-circle-up }\""); }
.#{$fa-css-prefix}-chevron-double-down:before { content: unquote("\"#{ $fa-var-chevron-double-down }\""); }
.#{$fa-css-prefix}-chevron-double-left:before { content: unquote("\"#{ $fa-var-chevron-double-left }\""); }
.#{$fa-css-prefix}-chevron-double-right:before { content: unquote("\"#{ $fa-var-chevron-double-right }\""); }
.#{$fa-css-prefix}-chevron-double-up:before { content: unquote("\"#{ $fa-var-chevron-double-up }\""); }
.#{$fa-css-prefix}-chevron-down:before { content: unquote("\"#{ $fa-var-chevron-down }\""); }
.#{$fa-css-prefix}-chevron-left:before { content: unquote("\"#{ $fa-var-chevron-left }\""); }
.#{$fa-css-prefix}-chevron-right:before { content: unquote("\"#{ $fa-var-chevron-right }\""); }
.#{$fa-css-prefix}-chevron-square-down:before { content: unquote("\"#{ $fa-var-chevron-square-down }\""); }
.#{$fa-css-prefix}-chevron-square-left:before { content: unquote("\"#{ $fa-var-chevron-square-left }\""); }
.#{$fa-css-prefix}-chevron-square-right:before { content: unquote("\"#{ $fa-var-chevron-square-right }\""); }
.#{$fa-css-prefix}-chevron-square-up:before { content: unquote("\"#{ $fa-var-chevron-square-up }\""); }
.#{$fa-css-prefix}-chevron-up:before { content: unquote("\"#{ $fa-var-chevron-up }\""); }
.#{$fa-css-prefix}-child:before { content: unquote("\"#{ $fa-var-child }\""); }
.#{$fa-css-prefix}-chrome:before { content: unquote("\"#{ $fa-var-chrome }\""); }
.#{$fa-css-prefix}-circle:before { content: unquote("\"#{ $fa-var-circle }\""); }
.#{$fa-css-prefix}-circle-notch:before { content: unquote("\"#{ $fa-var-circle-notch }\""); }
.#{$fa-css-prefix}-clipboard:before { content: unquote("\"#{ $fa-var-clipboard }\""); }
.#{$fa-css-prefix}-clock:before { content: unquote("\"#{ $fa-var-clock }\""); }
.#{$fa-css-prefix}-clone:before { content: unquote("\"#{ $fa-var-clone }\""); }
.#{$fa-css-prefix}-closed-captioning:before { content: unquote("\"#{ $fa-var-closed-captioning }\""); }
.#{$fa-css-prefix}-cloud:before { content: unquote("\"#{ $fa-var-cloud }\""); }
.#{$fa-css-prefix}-cloud-download:before { content: unquote("\"#{ $fa-var-cloud-download }\""); }
.#{$fa-css-prefix}-cloud-download-alt:before { content: unquote("\"#{ $fa-var-cloud-download-alt }\""); }
.#{$fa-css-prefix}-cloud-upload:before { content: unquote("\"#{ $fa-var-cloud-upload }\""); }
.#{$fa-css-prefix}-cloud-upload-alt:before { content: unquote("\"#{ $fa-var-cloud-upload-alt }\""); }
.#{$fa-css-prefix}-cloudscale:before { content: unquote("\"#{ $fa-var-cloudscale }\""); }
.#{$fa-css-prefix}-cloudsmith:before { content: unquote("\"#{ $fa-var-cloudsmith }\""); }
.#{$fa-css-prefix}-cloudversify:before { content: unquote("\"#{ $fa-var-cloudversify }\""); }
.#{$fa-css-prefix}-club:before { content: unquote("\"#{ $fa-var-club }\""); }
.#{$fa-css-prefix}-code:before { content: unquote("\"#{ $fa-var-code }\""); }
.#{$fa-css-prefix}-code-branch:before { content: unquote("\"#{ $fa-var-code-branch }\""); }
.#{$fa-css-prefix}-code-commit:before { content: unquote("\"#{ $fa-var-code-commit }\""); }
.#{$fa-css-prefix}-code-merge:before { content: unquote("\"#{ $fa-var-code-merge }\""); }
.#{$fa-css-prefix}-codepen:before { content: unquote("\"#{ $fa-var-codepen }\""); }
.#{$fa-css-prefix}-codiepie:before { content: unquote("\"#{ $fa-var-codiepie }\""); }
.#{$fa-css-prefix}-coffee:before { content: unquote("\"#{ $fa-var-coffee }\""); }
.#{$fa-css-prefix}-cog:before { content: unquote("\"#{ $fa-var-cog }\""); }
.#{$fa-css-prefix}-cogs:before { content: unquote("\"#{ $fa-var-cogs }\""); }
.#{$fa-css-prefix}-columns:before { content: unquote("\"#{ $fa-var-columns }\""); }
.#{$fa-css-prefix}-comment:before { content: unquote("\"#{ $fa-var-comment }\""); }
.#{$fa-css-prefix}-comment-alt:before { content: unquote("\"#{ $fa-var-comment-alt }\""); }
.#{$fa-css-prefix}-comments:before { content: unquote("\"#{ $fa-var-comments }\""); }
.#{$fa-css-prefix}-compass:before { content: unquote("\"#{ $fa-var-compass }\""); }
.#{$fa-css-prefix}-compress:before { content: unquote("\"#{ $fa-var-compress }\""); }
.#{$fa-css-prefix}-compress-wide:before { content: unquote("\"#{ $fa-var-compress-wide }\""); }
.#{$fa-css-prefix}-connectdevelop:before { content: unquote("\"#{ $fa-var-connectdevelop }\""); }
.#{$fa-css-prefix}-contao:before { content: unquote("\"#{ $fa-var-contao }\""); }
.#{$fa-css-prefix}-copy:before { content: unquote("\"#{ $fa-var-copy }\""); }
.#{$fa-css-prefix}-copyright:before { content: unquote("\"#{ $fa-var-copyright }\""); }
.#{$fa-css-prefix}-cpanel:before { content: unquote("\"#{ $fa-var-cpanel }\""); }
.#{$fa-css-prefix}-creative-commons:before { content: unquote("\"#{ $fa-var-creative-commons }\""); }
.#{$fa-css-prefix}-credit-card:before { content: unquote("\"#{ $fa-var-credit-card }\""); }
.#{$fa-css-prefix}-credit-card-blank:before { content: unquote("\"#{ $fa-var-credit-card-blank }\""); }
.#{$fa-css-prefix}-credit-card-front:before { content: unquote("\"#{ $fa-var-credit-card-front }\""); }
.#{$fa-css-prefix}-crop:before { content: unquote("\"#{ $fa-var-crop }\""); }
.#{$fa-css-prefix}-crosshairs:before { content: unquote("\"#{ $fa-var-crosshairs }\""); }
.#{$fa-css-prefix}-css3:before { content: unquote("\"#{ $fa-var-css3 }\""); }
.#{$fa-css-prefix}-css3-alt:before { content: unquote("\"#{ $fa-var-css3-alt }\""); }
.#{$fa-css-prefix}-cube:before { content: unquote("\"#{ $fa-var-cube }\""); }
.#{$fa-css-prefix}-cubes:before { content: unquote("\"#{ $fa-var-cubes }\""); }
.#{$fa-css-prefix}-cut:before { content: unquote("\"#{ $fa-var-cut }\""); }
.#{$fa-css-prefix}-cuttlefish:before { content: unquote("\"#{ $fa-var-cuttlefish }\""); }
.#{$fa-css-prefix}-d-and-d:before { content: unquote("\"#{ $fa-var-d-and-d }\""); }
.#{$fa-css-prefix}-dashcube:before { content: unquote("\"#{ $fa-var-dashcube }\""); }
.#{$fa-css-prefix}-database:before { content: unquote("\"#{ $fa-var-database }\""); }
.#{$fa-css-prefix}-deaf:before { content: unquote("\"#{ $fa-var-deaf }\""); }
.#{$fa-css-prefix}-delicious:before { content: unquote("\"#{ $fa-var-delicious }\""); }
.#{$fa-css-prefix}-deploydog:before { content: unquote("\"#{ $fa-var-deploydog }\""); }
.#{$fa-css-prefix}-deskpro:before { content: unquote("\"#{ $fa-var-deskpro }\""); }
.#{$fa-css-prefix}-desktop:before { content: unquote("\"#{ $fa-var-desktop }\""); }
.#{$fa-css-prefix}-desktop-alt:before { content: unquote("\"#{ $fa-var-desktop-alt }\""); }
.#{$fa-css-prefix}-deviantart:before { content: unquote("\"#{ $fa-var-deviantart }\""); }
.#{$fa-css-prefix}-diamond:before { content: unquote("\"#{ $fa-var-diamond }\""); }
.#{$fa-css-prefix}-digg:before { content: unquote("\"#{ $fa-var-digg }\""); }
.#{$fa-css-prefix}-digital-ocean:before { content: unquote("\"#{ $fa-var-digital-ocean }\""); }
.#{$fa-css-prefix}-discord:before { content: unquote("\"#{ $fa-var-discord }\""); }
.#{$fa-css-prefix}-discourse:before { content: unquote("\"#{ $fa-var-discourse }\""); }
.#{$fa-css-prefix}-dochub:before { content: unquote("\"#{ $fa-var-dochub }\""); }
.#{$fa-css-prefix}-docker:before { content: unquote("\"#{ $fa-var-docker }\""); }
.#{$fa-css-prefix}-dollar-sign:before { content: unquote("\"#{ $fa-var-dollar-sign }\""); }
.#{$fa-css-prefix}-dot-circle:before { content: unquote("\"#{ $fa-var-dot-circle }\""); }
.#{$fa-css-prefix}-download:before { content: unquote("\"#{ $fa-var-download }\""); }
.#{$fa-css-prefix}-draft2digital:before { content: unquote("\"#{ $fa-var-draft2digital }\""); }
.#{$fa-css-prefix}-dribbble:before { content: unquote("\"#{ $fa-var-dribbble }\""); }
.#{$fa-css-prefix}-dribbble-square:before { content: unquote("\"#{ $fa-var-dribbble-square }\""); }
.#{$fa-css-prefix}-dropbox:before { content: unquote("\"#{ $fa-var-dropbox }\""); }
.#{$fa-css-prefix}-dropbox-alt:before { content: unquote("\"#{ $fa-var-dropbox-alt }\""); }
.#{$fa-css-prefix}-drupal:before { content: unquote("\"#{ $fa-var-drupal }\""); }
.#{$fa-css-prefix}-dyalog:before { content: unquote("\"#{ $fa-var-dyalog }\""); }
.#{$fa-css-prefix}-earlybirds:before { content: unquote("\"#{ $fa-var-earlybirds }\""); }
.#{$fa-css-prefix}-edge:before { content: unquote("\"#{ $fa-var-edge }\""); }
.#{$fa-css-prefix}-edit:before { content: unquote("\"#{ $fa-var-edit }\""); }
.#{$fa-css-prefix}-eject:before { content: unquote("\"#{ $fa-var-eject }\""); }
.#{$fa-css-prefix}-ellipsis-h:before { content: unquote("\"#{ $fa-var-ellipsis-h }\""); }
.#{$fa-css-prefix}-ellipsis-h-alt:before { content: unquote("\"#{ $fa-var-ellipsis-h-alt }\""); }
.#{$fa-css-prefix}-ellipsis-v:before { content: unquote("\"#{ $fa-var-ellipsis-v }\""); }
.#{$fa-css-prefix}-ellipsis-v-alt:before { content: unquote("\"#{ $fa-var-ellipsis-v-alt }\""); }
.#{$fa-css-prefix}-empire:before { content: unquote("\"#{ $fa-var-empire }\""); }
.#{$fa-css-prefix}-envelope:before { content: unquote("\"#{ $fa-var-envelope }\""); }
.#{$fa-css-prefix}-envelope-open:before { content: unquote("\"#{ $fa-var-envelope-open }\""); }
.#{$fa-css-prefix}-envelope-square:before { content: unquote("\"#{ $fa-var-envelope-square }\""); }
.#{$fa-css-prefix}-envira:before { content: unquote("\"#{ $fa-var-envira }\""); }
.#{$fa-css-prefix}-eraser:before { content: unquote("\"#{ $fa-var-eraser }\""); }
.#{$fa-css-prefix}-erlang:before { content: unquote("\"#{ $fa-var-erlang }\""); }
.#{$fa-css-prefix}-etsy:before { content: unquote("\"#{ $fa-var-etsy }\""); }
.#{$fa-css-prefix}-euro-sign:before { content: unquote("\"#{ $fa-var-euro-sign }\""); }
.#{$fa-css-prefix}-exchange:before { content: unquote("\"#{ $fa-var-exchange }\""); }
.#{$fa-css-prefix}-exchange-alt:before { content: unquote("\"#{ $fa-var-exchange-alt }\""); }
.#{$fa-css-prefix}-exclamation:before { content: unquote("\"#{ $fa-var-exclamation }\""); }
.#{$fa-css-prefix}-exclamation-circle:before { content: unquote("\"#{ $fa-var-exclamation-circle }\""); }
.#{$fa-css-prefix}-exclamation-square:before { content: unquote("\"#{ $fa-var-exclamation-square }\""); }
.#{$fa-css-prefix}-exclamation-triangle:before { content: unquote("\"#{ $fa-var-exclamation-triangle }\""); }
.#{$fa-css-prefix}-expand:before { content: unquote("\"#{ $fa-var-expand }\""); }
.#{$fa-css-prefix}-expand-arrows:before { content: unquote("\"#{ $fa-var-expand-arrows }\""); }
.#{$fa-css-prefix}-expand-arrows-alt:before { content: unquote("\"#{ $fa-var-expand-arrows-alt }\""); }
.#{$fa-css-prefix}-expand-wide:before { content: unquote("\"#{ $fa-var-expand-wide }\""); }
.#{$fa-css-prefix}-expeditedssl:before { content: unquote("\"#{ $fa-var-expeditedssl }\""); }
.#{$fa-css-prefix}-external-link:before { content: unquote("\"#{ $fa-var-external-link }\""); }
.#{$fa-css-prefix}-external-link-alt:before { content: unquote("\"#{ $fa-var-external-link-alt }\""); }
.#{$fa-css-prefix}-external-link-square:before { content: unquote("\"#{ $fa-var-external-link-square }\""); }
.#{$fa-css-prefix}-external-link-square-alt:before { content: unquote("\"#{ $fa-var-external-link-square-alt }\""); }
.#{$fa-css-prefix}-eye:before { content: unquote("\"#{ $fa-var-eye }\""); }
.#{$fa-css-prefix}-eye-dropper:before { content: unquote("\"#{ $fa-var-eye-dropper }\""); }
.#{$fa-css-prefix}-eye-slash:before { content: unquote("\"#{ $fa-var-eye-slash }\""); }
.#{$fa-css-prefix}-facebook:before { content: unquote("\"#{ $fa-var-facebook }\""); }
.#{$fa-css-prefix}-facebook-f:before { content: unquote("\"#{ $fa-var-facebook-f }\""); }
.#{$fa-css-prefix}-facebook-messenger:before { content: unquote("\"#{ $fa-var-facebook-messenger }\""); }
.#{$fa-css-prefix}-facebook-square:before { content: unquote("\"#{ $fa-var-facebook-square }\""); }
.#{$fa-css-prefix}-fast-backward:before { content: unquote("\"#{ $fa-var-fast-backward }\""); }
.#{$fa-css-prefix}-fast-forward:before { content: unquote("\"#{ $fa-var-fast-forward }\""); }
.#{$fa-css-prefix}-fax:before { content: unquote("\"#{ $fa-var-fax }\""); }
.#{$fa-css-prefix}-female:before { content: unquote("\"#{ $fa-var-female }\""); }
.#{$fa-css-prefix}-fighter-jet:before { content: unquote("\"#{ $fa-var-fighter-jet }\""); }
.#{$fa-css-prefix}-file:before { content: unquote("\"#{ $fa-var-file }\""); }
.#{$fa-css-prefix}-file-alt:before { content: unquote("\"#{ $fa-var-file-alt }\""); }
.#{$fa-css-prefix}-file-archive:before { content: unquote("\"#{ $fa-var-file-archive }\""); }
.#{$fa-css-prefix}-file-audio:before { content: unquote("\"#{ $fa-var-file-audio }\""); }
.#{$fa-css-prefix}-file-check:before { content: unquote("\"#{ $fa-var-file-check }\""); }
.#{$fa-css-prefix}-file-code:before { content: unquote("\"#{ $fa-var-file-code }\""); }
.#{$fa-css-prefix}-file-edit:before { content: unquote("\"#{ $fa-var-file-edit }\""); }
.#{$fa-css-prefix}-file-excel:before { content: unquote("\"#{ $fa-var-file-excel }\""); }
.#{$fa-css-prefix}-file-exclamation:before { content: unquote("\"#{ $fa-var-file-exclamation }\""); }
.#{$fa-css-prefix}-file-image:before { content: unquote("\"#{ $fa-var-file-image }\""); }
.#{$fa-css-prefix}-file-minus:before { content: unquote("\"#{ $fa-var-file-minus }\""); }
.#{$fa-css-prefix}-file-pdf:before { content: unquote("\"#{ $fa-var-file-pdf }\""); }
.#{$fa-css-prefix}-file-plus:before { content: unquote("\"#{ $fa-var-file-plus }\""); }
.#{$fa-css-prefix}-file-powerpoint:before { content: unquote("\"#{ $fa-var-file-powerpoint }\""); }
.#{$fa-css-prefix}-file-times:before { content: unquote("\"#{ $fa-var-file-times }\""); }
.#{$fa-css-prefix}-file-video:before { content: unquote("\"#{ $fa-var-file-video }\""); }
.#{$fa-css-prefix}-file-word:before { content: unquote("\"#{ $fa-var-file-word }\""); }
.#{$fa-css-prefix}-film:before { content: unquote("\"#{ $fa-var-film }\""); }
.#{$fa-css-prefix}-film-alt:before { content: unquote("\"#{ $fa-var-film-alt }\""); }
.#{$fa-css-prefix}-filter:before { content: unquote("\"#{ $fa-var-filter }\""); }
.#{$fa-css-prefix}-fire:before { content: unquote("\"#{ $fa-var-fire }\""); }
.#{$fa-css-prefix}-fire-extinguisher:before { content: unquote("\"#{ $fa-var-fire-extinguisher }\""); }
.#{$fa-css-prefix}-firefox:before { content: unquote("\"#{ $fa-var-firefox }\""); }
.#{$fa-css-prefix}-first-order:before { content: unquote("\"#{ $fa-var-first-order }\""); }
.#{$fa-css-prefix}-firstdraft:before { content: unquote("\"#{ $fa-var-firstdraft }\""); }
.#{$fa-css-prefix}-flag:before { content: unquote("\"#{ $fa-var-flag }\""); }
.#{$fa-css-prefix}-flag-checkered:before { content: unquote("\"#{ $fa-var-flag-checkered }\""); }
.#{$fa-css-prefix}-flask:before { content: unquote("\"#{ $fa-var-flask }\""); }
.#{$fa-css-prefix}-flickr:before { content: unquote("\"#{ $fa-var-flickr }\""); }
.#{$fa-css-prefix}-fly:before { content: unquote("\"#{ $fa-var-fly }\""); }
.#{$fa-css-prefix}-folder:before { content: unquote("\"#{ $fa-var-folder }\""); }
.#{$fa-css-prefix}-folder-open:before { content: unquote("\"#{ $fa-var-folder-open }\""); }
.#{$fa-css-prefix}-font:before { content: unquote("\"#{ $fa-var-font }\""); }
.#{$fa-css-prefix}-font-awesome:before { content: unquote("\"#{ $fa-var-font-awesome }\""); }
.#{$fa-css-prefix}-font-awesome-alt:before { content: unquote("\"#{ $fa-var-font-awesome-alt }\""); }
.#{$fa-css-prefix}-fonticons:before { content: unquote("\"#{ $fa-var-fonticons }\""); }
.#{$fa-css-prefix}-fonticons-fi:before { content: unquote("\"#{ $fa-var-fonticons-fi }\""); }
.#{$fa-css-prefix}-fort-awesome:before { content: unquote("\"#{ $fa-var-fort-awesome }\""); }
.#{$fa-css-prefix}-fort-awesome-alt:before { content: unquote("\"#{ $fa-var-fort-awesome-alt }\""); }
.#{$fa-css-prefix}-forumbee:before { content: unquote("\"#{ $fa-var-forumbee }\""); }
.#{$fa-css-prefix}-forward:before { content: unquote("\"#{ $fa-var-forward }\""); }
.#{$fa-css-prefix}-foursquare:before { content: unquote("\"#{ $fa-var-foursquare }\""); }
.#{$fa-css-prefix}-free-code-camp:before { content: unquote("\"#{ $fa-var-free-code-camp }\""); }
.#{$fa-css-prefix}-freebsd:before { content: unquote("\"#{ $fa-var-freebsd }\""); }
.#{$fa-css-prefix}-frown:before { content: unquote("\"#{ $fa-var-frown }\""); }
.#{$fa-css-prefix}-futbol:before { content: unquote("\"#{ $fa-var-futbol }\""); }
.#{$fa-css-prefix}-gamepad:before { content: unquote("\"#{ $fa-var-gamepad }\""); }
.#{$fa-css-prefix}-gavel:before { content: unquote("\"#{ $fa-var-gavel }\""); }
.#{$fa-css-prefix}-gem:before { content: unquote("\"#{ $fa-var-gem }\""); }
.#{$fa-css-prefix}-genderless:before { content: unquote("\"#{ $fa-var-genderless }\""); }
.#{$fa-css-prefix}-get-pocket:before { content: unquote("\"#{ $fa-var-get-pocket }\""); }
.#{$fa-css-prefix}-gg:before { content: unquote("\"#{ $fa-var-gg }\""); }
.#{$fa-css-prefix}-gg-circle:before { content: unquote("\"#{ $fa-var-gg-circle }\""); }
.#{$fa-css-prefix}-gift:before { content: unquote("\"#{ $fa-var-gift }\""); }
.#{$fa-css-prefix}-git:before { content: unquote("\"#{ $fa-var-git }\""); }
.#{$fa-css-prefix}-git-square:before { content: unquote("\"#{ $fa-var-git-square }\""); }
.#{$fa-css-prefix}-github:before { content: unquote("\"#{ $fa-var-github }\""); }
.#{$fa-css-prefix}-github-alt:before { content: unquote("\"#{ $fa-var-github-alt }\""); }
.#{$fa-css-prefix}-github-square:before { content: unquote("\"#{ $fa-var-github-square }\""); }
.#{$fa-css-prefix}-gitkraken:before { content: unquote("\"#{ $fa-var-gitkraken }\""); }
.#{$fa-css-prefix}-gitlab:before { content: unquote("\"#{ $fa-var-gitlab }\""); }
.#{$fa-css-prefix}-glass-martini:before { content: unquote("\"#{ $fa-var-glass-martini }\""); }
.#{$fa-css-prefix}-glide:before { content: unquote("\"#{ $fa-var-glide }\""); }
.#{$fa-css-prefix}-glide-g:before { content: unquote("\"#{ $fa-var-glide-g }\""); }
.#{$fa-css-prefix}-globe:before { content: unquote("\"#{ $fa-var-globe }\""); }
.#{$fa-css-prefix}-gofore:before { content: unquote("\"#{ $fa-var-gofore }\""); }
.#{$fa-css-prefix}-goodreads:before { content: unquote("\"#{ $fa-var-goodreads }\""); }
.#{$fa-css-prefix}-goodreads-g:before { content: unquote("\"#{ $fa-var-goodreads-g }\""); }
.#{$fa-css-prefix}-google:before { content: unquote("\"#{ $fa-var-google }\""); }
.#{$fa-css-prefix}-google-drive:before { content: unquote("\"#{ $fa-var-google-drive }\""); }
.#{$fa-css-prefix}-google-play:before { content: unquote("\"#{ $fa-var-google-play }\""); }
.#{$fa-css-prefix}-google-plus:before { content: unquote("\"#{ $fa-var-google-plus }\""); }
.#{$fa-css-prefix}-google-plus-g:before { content: unquote("\"#{ $fa-var-google-plus-g }\""); }
.#{$fa-css-prefix}-google-plus-square:before { content: unquote("\"#{ $fa-var-google-plus-square }\""); }
.#{$fa-css-prefix}-google-wallet:before { content: unquote("\"#{ $fa-var-google-wallet }\""); }
.#{$fa-css-prefix}-graduation-cap:before { content: unquote("\"#{ $fa-var-graduation-cap }\""); }
.#{$fa-css-prefix}-gratipay:before { content: unquote("\"#{ $fa-var-gratipay }\""); }
.#{$fa-css-prefix}-grav:before { content: unquote("\"#{ $fa-var-grav }\""); }
.#{$fa-css-prefix}-gripfire:before { content: unquote("\"#{ $fa-var-gripfire }\""); }
.#{$fa-css-prefix}-grunt:before { content: unquote("\"#{ $fa-var-grunt }\""); }
.#{$fa-css-prefix}-gulp:before { content: unquote("\"#{ $fa-var-gulp }\""); }
.#{$fa-css-prefix}-h-square:before { content: unquote("\"#{ $fa-var-h-square }\""); }
.#{$fa-css-prefix}-h1:before { content: unquote("\"#{ $fa-var-h1 }\""); }
.#{$fa-css-prefix}-h2:before { content: unquote("\"#{ $fa-var-h2 }\""); }
.#{$fa-css-prefix}-h3:before { content: unquote("\"#{ $fa-var-h3 }\""); }
.#{$fa-css-prefix}-hacker-news:before { content: unquote("\"#{ $fa-var-hacker-news }\""); }
.#{$fa-css-prefix}-hacker-news-square:before { content: unquote("\"#{ $fa-var-hacker-news-square }\""); }
.#{$fa-css-prefix}-hand-lizard:before { content: unquote("\"#{ $fa-var-hand-lizard }\""); }
.#{$fa-css-prefix}-hand-paper:before { content: unquote("\"#{ $fa-var-hand-paper }\""); }
.#{$fa-css-prefix}-hand-peace:before { content: unquote("\"#{ $fa-var-hand-peace }\""); }
.#{$fa-css-prefix}-hand-point-down:before { content: unquote("\"#{ $fa-var-hand-point-down }\""); }
.#{$fa-css-prefix}-hand-point-left:before { content: unquote("\"#{ $fa-var-hand-point-left }\""); }
.#{$fa-css-prefix}-hand-point-right:before { content: unquote("\"#{ $fa-var-hand-point-right }\""); }
.#{$fa-css-prefix}-hand-point-up:before { content: unquote("\"#{ $fa-var-hand-point-up }\""); }
.#{$fa-css-prefix}-hand-pointer:before { content: unquote("\"#{ $fa-var-hand-pointer }\""); }
.#{$fa-css-prefix}-hand-rock:before { content: unquote("\"#{ $fa-var-hand-rock }\""); }
.#{$fa-css-prefix}-hand-scissors:before { content: unquote("\"#{ $fa-var-hand-scissors }\""); }
.#{$fa-css-prefix}-hand-spock:before { content: unquote("\"#{ $fa-var-hand-spock }\""); }
.#{$fa-css-prefix}-handshake:before { content: unquote("\"#{ $fa-var-handshake }\""); }
.#{$fa-css-prefix}-hashtag:before { content: unquote("\"#{ $fa-var-hashtag }\""); }
.#{$fa-css-prefix}-hdd:before { content: unquote("\"#{ $fa-var-hdd }\""); }
.#{$fa-css-prefix}-heading:before { content: unquote("\"#{ $fa-var-heading }\""); }
.#{$fa-css-prefix}-headphones:before { content: unquote("\"#{ $fa-var-headphones }\""); }
.#{$fa-css-prefix}-heart:before { content: unquote("\"#{ $fa-var-heart }\""); }
.#{$fa-css-prefix}-heartbeat:before { content: unquote("\"#{ $fa-var-heartbeat }\""); }
.#{$fa-css-prefix}-hexagon:before { content: unquote("\"#{ $fa-var-hexagon }\""); }
.#{$fa-css-prefix}-hire-a-helper:before { content: unquote("\"#{ $fa-var-hire-a-helper }\""); }
.#{$fa-css-prefix}-history:before { content: unquote("\"#{ $fa-var-history }\""); }
.#{$fa-css-prefix}-home:before { content: unquote("\"#{ $fa-var-home }\""); }
.#{$fa-css-prefix}-hospital:before { content: unquote("\"#{ $fa-var-hospital }\""); }
.#{$fa-css-prefix}-hotjar:before { content: unquote("\"#{ $fa-var-hotjar }\""); }
.#{$fa-css-prefix}-hourglass:before { content: unquote("\"#{ $fa-var-hourglass }\""); }
.#{$fa-css-prefix}-hourglass-end:before { content: unquote("\"#{ $fa-var-hourglass-end }\""); }
.#{$fa-css-prefix}-hourglass-half:before { content: unquote("\"#{ $fa-var-hourglass-half }\""); }
.#{$fa-css-prefix}-hourglass-start:before { content: unquote("\"#{ $fa-var-hourglass-start }\""); }
.#{$fa-css-prefix}-houzz:before { content: unquote("\"#{ $fa-var-houzz }\""); }
.#{$fa-css-prefix}-html5:before { content: unquote("\"#{ $fa-var-html5 }\""); }
.#{$fa-css-prefix}-hubspot:before { content: unquote("\"#{ $fa-var-hubspot }\""); }
.#{$fa-css-prefix}-i-cursor:before { content: unquote("\"#{ $fa-var-i-cursor }\""); }
.#{$fa-css-prefix}-id-badge:before { content: unquote("\"#{ $fa-var-id-badge }\""); }
.#{$fa-css-prefix}-id-card:before { content: unquote("\"#{ $fa-var-id-card }\""); }
.#{$fa-css-prefix}-image:before { content: unquote("\"#{ $fa-var-image }\""); }
.#{$fa-css-prefix}-images:before { content: unquote("\"#{ $fa-var-images }\""); }
.#{$fa-css-prefix}-imdb:before { content: unquote("\"#{ $fa-var-imdb }\""); }
.#{$fa-css-prefix}-inbox:before { content: unquote("\"#{ $fa-var-inbox }\""); }
.#{$fa-css-prefix}-inbox-in:before { content: unquote("\"#{ $fa-var-inbox-in }\""); }
.#{$fa-css-prefix}-inbox-out:before { content: unquote("\"#{ $fa-var-inbox-out }\""); }
.#{$fa-css-prefix}-indent:before { content: unquote("\"#{ $fa-var-indent }\""); }
.#{$fa-css-prefix}-industry:before { content: unquote("\"#{ $fa-var-industry }\""); }
.#{$fa-css-prefix}-industry-alt:before { content: unquote("\"#{ $fa-var-industry-alt }\""); }
.#{$fa-css-prefix}-info:before { content: unquote("\"#{ $fa-var-info }\""); }
.#{$fa-css-prefix}-info-circle:before { content: unquote("\"#{ $fa-var-info-circle }\""); }
.#{$fa-css-prefix}-info-square:before { content: unquote("\"#{ $fa-var-info-square }\""); }
.#{$fa-css-prefix}-instagram:before { content: unquote("\"#{ $fa-var-instagram }\""); }
.#{$fa-css-prefix}-internet-explorer:before { content: unquote("\"#{ $fa-var-internet-explorer }\""); }
.#{$fa-css-prefix}-ioxhost:before { content: unquote("\"#{ $fa-var-ioxhost }\""); }
.#{$fa-css-prefix}-italic:before { content: unquote("\"#{ $fa-var-italic }\""); }
.#{$fa-css-prefix}-itunes:before { content: unquote("\"#{ $fa-var-itunes }\""); }
.#{$fa-css-prefix}-itunes-note:before { content: unquote("\"#{ $fa-var-itunes-note }\""); }
.#{$fa-css-prefix}-jack-o-lantern:before { content: unquote("\"#{ $fa-var-jack-o-lantern }\""); }
.#{$fa-css-prefix}-jenkins:before { content: unquote("\"#{ $fa-var-jenkins }\""); }
.#{$fa-css-prefix}-joget:before { content: unquote("\"#{ $fa-var-joget }\""); }
.#{$fa-css-prefix}-joomla:before { content: unquote("\"#{ $fa-var-joomla }\""); }
.#{$fa-css-prefix}-js:before { content: unquote("\"#{ $fa-var-js }\""); }
.#{$fa-css-prefix}-js-square:before { content: unquote("\"#{ $fa-var-js-square }\""); }
.#{$fa-css-prefix}-jsfiddle:before { content: unquote("\"#{ $fa-var-jsfiddle }\""); }
.#{$fa-css-prefix}-key:before { content: unquote("\"#{ $fa-var-key }\""); }
.#{$fa-css-prefix}-keyboard:before { content: unquote("\"#{ $fa-var-keyboard }\""); }
.#{$fa-css-prefix}-keycdn:before { content: unquote("\"#{ $fa-var-keycdn }\""); }
.#{$fa-css-prefix}-kickstarter:before { content: unquote("\"#{ $fa-var-kickstarter }\""); }
.#{$fa-css-prefix}-kickstarter-k:before { content: unquote("\"#{ $fa-var-kickstarter-k }\""); }
.#{$fa-css-prefix}-language:before { content: unquote("\"#{ $fa-var-language }\""); }
.#{$fa-css-prefix}-laptop:before { content: unquote("\"#{ $fa-var-laptop }\""); }
.#{$fa-css-prefix}-laravel:before { content: unquote("\"#{ $fa-var-laravel }\""); }
.#{$fa-css-prefix}-lastfm:before { content: unquote("\"#{ $fa-var-lastfm }\""); }
.#{$fa-css-prefix}-lastfm-square:before { content: unquote("\"#{ $fa-var-lastfm-square }\""); }
.#{$fa-css-prefix}-leaf:before { content: unquote("\"#{ $fa-var-leaf }\""); }
.#{$fa-css-prefix}-leanpub:before { content: unquote("\"#{ $fa-var-leanpub }\""); }
.#{$fa-css-prefix}-lemon:before { content: unquote("\"#{ $fa-var-lemon }\""); }
.#{$fa-css-prefix}-less:before { content: unquote("\"#{ $fa-var-less }\""); }
.#{$fa-css-prefix}-level-down:before { content: unquote("\"#{ $fa-var-level-down }\""); }
.#{$fa-css-prefix}-level-down-alt:before { content: unquote("\"#{ $fa-var-level-down-alt }\""); }
.#{$fa-css-prefix}-level-up:before { content: unquote("\"#{ $fa-var-level-up }\""); }
.#{$fa-css-prefix}-level-up-alt:before { content: unquote("\"#{ $fa-var-level-up-alt }\""); }
.#{$fa-css-prefix}-life-ring:before { content: unquote("\"#{ $fa-var-life-ring }\""); }
.#{$fa-css-prefix}-lightbulb:before { content: unquote("\"#{ $fa-var-lightbulb }\""); }
.#{$fa-css-prefix}-line:before { content: unquote("\"#{ $fa-var-line }\""); }
.#{$fa-css-prefix}-link:before { content: unquote("\"#{ $fa-var-link }\""); }
.#{$fa-css-prefix}-linkedin:before { content: unquote("\"#{ $fa-var-linkedin }\""); }
.#{$fa-css-prefix}-linkedin-in:before { content: unquote("\"#{ $fa-var-linkedin-in }\""); }
.#{$fa-css-prefix}-linode:before { content: unquote("\"#{ $fa-var-linode }\""); }
.#{$fa-css-prefix}-linux:before { content: unquote("\"#{ $fa-var-linux }\""); }
.#{$fa-css-prefix}-lira-sign:before { content: unquote("\"#{ $fa-var-lira-sign }\""); }
.#{$fa-css-prefix}-list:before { content: unquote("\"#{ $fa-var-list }\""); }
.#{$fa-css-prefix}-list-alt:before { content: unquote("\"#{ $fa-var-list-alt }\""); }
.#{$fa-css-prefix}-list-ol:before { content: unquote("\"#{ $fa-var-list-ol }\""); }
.#{$fa-css-prefix}-list-ul:before { content: unquote("\"#{ $fa-var-list-ul }\""); }
.#{$fa-css-prefix}-location-arrow:before { content: unquote("\"#{ $fa-var-location-arrow }\""); }
.#{$fa-css-prefix}-lock:before { content: unquote("\"#{ $fa-var-lock }\""); }
.#{$fa-css-prefix}-lock-alt:before { content: unquote("\"#{ $fa-var-lock-alt }\""); }
.#{$fa-css-prefix}-lock-open:before { content: unquote("\"#{ $fa-var-lock-open }\""); }
.#{$fa-css-prefix}-lock-open-alt:before { content: unquote("\"#{ $fa-var-lock-open-alt }\""); }
.#{$fa-css-prefix}-long-arrow-alt-down:before { content: unquote("\"#{ $fa-var-long-arrow-alt-down }\""); }
.#{$fa-css-prefix}-long-arrow-alt-left:before { content: unquote("\"#{ $fa-var-long-arrow-alt-left }\""); }
.#{$fa-css-prefix}-long-arrow-alt-right:before { content: unquote("\"#{ $fa-var-long-arrow-alt-right }\""); }
.#{$fa-css-prefix}-long-arrow-alt-up:before { content: unquote("\"#{ $fa-var-long-arrow-alt-up }\""); }
.#{$fa-css-prefix}-long-arrow-down:before { content: unquote("\"#{ $fa-var-long-arrow-down }\""); }
.#{$fa-css-prefix}-long-arrow-left:before { content: unquote("\"#{ $fa-var-long-arrow-left }\""); }
.#{$fa-css-prefix}-long-arrow-right:before { content: unquote("\"#{ $fa-var-long-arrow-right }\""); }
.#{$fa-css-prefix}-long-arrow-up:before { content: unquote("\"#{ $fa-var-long-arrow-up }\""); }
.#{$fa-css-prefix}-low-vision:before { content: unquote("\"#{ $fa-var-low-vision }\""); }
.#{$fa-css-prefix}-lyft:before { content: unquote("\"#{ $fa-var-lyft }\""); }
.#{$fa-css-prefix}-magento:before { content: unquote("\"#{ $fa-var-magento }\""); }
.#{$fa-css-prefix}-magic:before { content: unquote("\"#{ $fa-var-magic }\""); }
.#{$fa-css-prefix}-magnet:before { content: unquote("\"#{ $fa-var-magnet }\""); }
.#{$fa-css-prefix}-male:before { content: unquote("\"#{ $fa-var-male }\""); }
.#{$fa-css-prefix}-map:before { content: unquote("\"#{ $fa-var-map }\""); }
.#{$fa-css-prefix}-map-marker:before { content: unquote("\"#{ $fa-var-map-marker }\""); }
.#{$fa-css-prefix}-map-marker-alt:before { content: unquote("\"#{ $fa-var-map-marker-alt }\""); }
.#{$fa-css-prefix}-map-pin:before { content: unquote("\"#{ $fa-var-map-pin }\""); }
.#{$fa-css-prefix}-map-signs:before { content: unquote("\"#{ $fa-var-map-signs }\""); }
.#{$fa-css-prefix}-mars:before { content: unquote("\"#{ $fa-var-mars }\""); }
.#{$fa-css-prefix}-mars-double:before { content: unquote("\"#{ $fa-var-mars-double }\""); }
.#{$fa-css-prefix}-mars-stroke:before { content: unquote("\"#{ $fa-var-mars-stroke }\""); }
.#{$fa-css-prefix}-mars-stroke-h:before { content: unquote("\"#{ $fa-var-mars-stroke-h }\""); }
.#{$fa-css-prefix}-mars-stroke-v:before { content: unquote("\"#{ $fa-var-mars-stroke-v }\""); }
.#{$fa-css-prefix}-maxcdn:before { content: unquote("\"#{ $fa-var-maxcdn }\""); }
.#{$fa-css-prefix}-medapps:before { content: unquote("\"#{ $fa-var-medapps }\""); }
.#{$fa-css-prefix}-medium:before { content: unquote("\"#{ $fa-var-medium }\""); }
.#{$fa-css-prefix}-medium-m:before { content: unquote("\"#{ $fa-var-medium-m }\""); }
.#{$fa-css-prefix}-medkit:before { content: unquote("\"#{ $fa-var-medkit }\""); }
.#{$fa-css-prefix}-medrt:before { content: unquote("\"#{ $fa-var-medrt }\""); }
.#{$fa-css-prefix}-meetup:before { content: unquote("\"#{ $fa-var-meetup }\""); }
.#{$fa-css-prefix}-meh:before { content: unquote("\"#{ $fa-var-meh }\""); }
.#{$fa-css-prefix}-mercury:before { content: unquote("\"#{ $fa-var-mercury }\""); }
.#{$fa-css-prefix}-microchip:before { content: unquote("\"#{ $fa-var-microchip }\""); }
.#{$fa-css-prefix}-microphone:before { content: unquote("\"#{ $fa-var-microphone }\""); }
.#{$fa-css-prefix}-microphone-alt:before { content: unquote("\"#{ $fa-var-microphone-alt }\""); }
.#{$fa-css-prefix}-microphone-slash:before { content: unquote("\"#{ $fa-var-microphone-slash }\""); }
.#{$fa-css-prefix}-microsoft:before { content: unquote("\"#{ $fa-var-microsoft }\""); }
.#{$fa-css-prefix}-minus:before { content: unquote("\"#{ $fa-var-minus }\""); }
.#{$fa-css-prefix}-minus-circle:before { content: unquote("\"#{ $fa-var-minus-circle }\""); }
.#{$fa-css-prefix}-minus-hexagon:before { content: unquote("\"#{ $fa-var-minus-hexagon }\""); }
.#{$fa-css-prefix}-minus-octagon:before { content: unquote("\"#{ $fa-var-minus-octagon }\""); }
.#{$fa-css-prefix}-minus-square:before { content: unquote("\"#{ $fa-var-minus-square }\""); }
.#{$fa-css-prefix}-mix:before { content: unquote("\"#{ $fa-var-mix }\""); }
.#{$fa-css-prefix}-mixcloud:before { content: unquote("\"#{ $fa-var-mixcloud }\""); }
.#{$fa-css-prefix}-mizuni:before { content: unquote("\"#{ $fa-var-mizuni }\""); }
.#{$fa-css-prefix}-mobile:before { content: unquote("\"#{ $fa-var-mobile }\""); }
.#{$fa-css-prefix}-mobile-alt:before { content: unquote("\"#{ $fa-var-mobile-alt }\""); }
.#{$fa-css-prefix}-mobile-android:before { content: unquote("\"#{ $fa-var-mobile-android }\""); }
.#{$fa-css-prefix}-mobile-android-alt:before { content: unquote("\"#{ $fa-var-mobile-android-alt }\""); }
.#{$fa-css-prefix}-modx:before { content: unquote("\"#{ $fa-var-modx }\""); }
.#{$fa-css-prefix}-monero:before { content: unquote("\"#{ $fa-var-monero }\""); }
.#{$fa-css-prefix}-money-bill:before { content: unquote("\"#{ $fa-var-money-bill }\""); }
.#{$fa-css-prefix}-money-bill-alt:before { content: unquote("\"#{ $fa-var-money-bill-alt }\""); }
.#{$fa-css-prefix}-moon:before { content: unquote("\"#{ $fa-var-moon }\""); }
.#{$fa-css-prefix}-motorcycle:before { content: unquote("\"#{ $fa-var-motorcycle }\""); }
.#{$fa-css-prefix}-mouse-pointer:before { content: unquote("\"#{ $fa-var-mouse-pointer }\""); }
.#{$fa-css-prefix}-music:before { content: unquote("\"#{ $fa-var-music }\""); }
.#{$fa-css-prefix}-napster:before { content: unquote("\"#{ $fa-var-napster }\""); }
.#{$fa-css-prefix}-neuter:before { content: unquote("\"#{ $fa-var-neuter }\""); }
.#{$fa-css-prefix}-newspaper:before { content: unquote("\"#{ $fa-var-newspaper }\""); }
.#{$fa-css-prefix}-nintendo-switch:before { content: unquote("\"#{ $fa-var-nintendo-switch }\""); }
.#{$fa-css-prefix}-node:before { content: unquote("\"#{ $fa-var-node }\""); }
.#{$fa-css-prefix}-node-js:before { content: unquote("\"#{ $fa-var-node-js }\""); }
.#{$fa-css-prefix}-npm:before { content: unquote("\"#{ $fa-var-npm }\""); }
.#{$fa-css-prefix}-ns8:before { content: unquote("\"#{ $fa-var-ns8 }\""); }
.#{$fa-css-prefix}-nutritionix:before { content: unquote("\"#{ $fa-var-nutritionix }\""); }
.#{$fa-css-prefix}-object-group:before { content: unquote("\"#{ $fa-var-object-group }\""); }
.#{$fa-css-prefix}-object-ungroup:before { content: unquote("\"#{ $fa-var-object-ungroup }\""); }
.#{$fa-css-prefix}-octagon:before { content: unquote("\"#{ $fa-var-octagon }\""); }
.#{$fa-css-prefix}-odnoklassniki:before { content: unquote("\"#{ $fa-var-odnoklassniki }\""); }
.#{$fa-css-prefix}-odnoklassniki-square:before { content: unquote("\"#{ $fa-var-odnoklassniki-square }\""); }
.#{$fa-css-prefix}-opencart:before { content: unquote("\"#{ $fa-var-opencart }\""); }
.#{$fa-css-prefix}-openid:before { content: unquote("\"#{ $fa-var-openid }\""); }
.#{$fa-css-prefix}-opera:before { content: unquote("\"#{ $fa-var-opera }\""); }
.#{$fa-css-prefix}-optin-monster:before { content: unquote("\"#{ $fa-var-optin-monster }\""); }
.#{$fa-css-prefix}-osi:before { content: unquote("\"#{ $fa-var-osi }\""); }
.#{$fa-css-prefix}-outdent:before { content: unquote("\"#{ $fa-var-outdent }\""); }
.#{$fa-css-prefix}-page4:before { content: unquote("\"#{ $fa-var-page4 }\""); }
.#{$fa-css-prefix}-pagelines:before { content: unquote("\"#{ $fa-var-pagelines }\""); }
.#{$fa-css-prefix}-paint-brush:before { content: unquote("\"#{ $fa-var-paint-brush }\""); }
.#{$fa-css-prefix}-palfed:before { content: unquote("\"#{ $fa-var-palfed }\""); }
.#{$fa-css-prefix}-paper-plane:before { content: unquote("\"#{ $fa-var-paper-plane }\""); }
.#{$fa-css-prefix}-paperclip:before { content: unquote("\"#{ $fa-var-paperclip }\""); }
.#{$fa-css-prefix}-paragraph:before { content: unquote("\"#{ $fa-var-paragraph }\""); }
.#{$fa-css-prefix}-paste:before { content: unquote("\"#{ $fa-var-paste }\""); }
.#{$fa-css-prefix}-patreon:before { content: unquote("\"#{ $fa-var-patreon }\""); }
.#{$fa-css-prefix}-pause:before { content: unquote("\"#{ $fa-var-pause }\""); }
.#{$fa-css-prefix}-pause-circle:before { content: unquote("\"#{ $fa-var-pause-circle }\""); }
.#{$fa-css-prefix}-paw:before { content: unquote("\"#{ $fa-var-paw }\""); }
.#{$fa-css-prefix}-paypal:before { content: unquote("\"#{ $fa-var-paypal }\""); }
.#{$fa-css-prefix}-pen:before { content: unquote("\"#{ $fa-var-pen }\""); }
.#{$fa-css-prefix}-pen-alt:before { content: unquote("\"#{ $fa-var-pen-alt }\""); }
.#{$fa-css-prefix}-pen-square:before { content: unquote("\"#{ $fa-var-pen-square }\""); }
.#{$fa-css-prefix}-pencil:before { content: unquote("\"#{ $fa-var-pencil }\""); }
.#{$fa-css-prefix}-pencil-alt:before { content: unquote("\"#{ $fa-var-pencil-alt }\""); }
.#{$fa-css-prefix}-percent:before { content: unquote("\"#{ $fa-var-percent }\""); }
.#{$fa-css-prefix}-periscope:before { content: unquote("\"#{ $fa-var-periscope }\""); }
.#{$fa-css-prefix}-phabricator:before { content: unquote("\"#{ $fa-var-phabricator }\""); }
.#{$fa-css-prefix}-phoenix-framework:before { content: unquote("\"#{ $fa-var-phoenix-framework }\""); }
.#{$fa-css-prefix}-phone:before { content: unquote("\"#{ $fa-var-phone }\""); }
.#{$fa-css-prefix}-phone-slash:before { content: unquote("\"#{ $fa-var-phone-slash }\""); }
.#{$fa-css-prefix}-phone-square:before { content: unquote("\"#{ $fa-var-phone-square }\""); }
.#{$fa-css-prefix}-phone-volume:before { content: unquote("\"#{ $fa-var-phone-volume }\""); }
.#{$fa-css-prefix}-pied-piper:before { content: unquote("\"#{ $fa-var-pied-piper }\""); }
.#{$fa-css-prefix}-pied-piper-alt:before { content: unquote("\"#{ $fa-var-pied-piper-alt }\""); }
.#{$fa-css-prefix}-pied-piper-pp:before { content: unquote("\"#{ $fa-var-pied-piper-pp }\""); }
.#{$fa-css-prefix}-pinterest:before { content: unquote("\"#{ $fa-var-pinterest }\""); }
.#{$fa-css-prefix}-pinterest-p:before { content: unquote("\"#{ $fa-var-pinterest-p }\""); }
.#{$fa-css-prefix}-pinterest-square:before { content: unquote("\"#{ $fa-var-pinterest-square }\""); }
.#{$fa-css-prefix}-plane:before { content: unquote("\"#{ $fa-var-plane }\""); }
.#{$fa-css-prefix}-plane-alt:before { content: unquote("\"#{ $fa-var-plane-alt }\""); }
.#{$fa-css-prefix}-play:before { content: unquote("\"#{ $fa-var-play }\""); }
.#{$fa-css-prefix}-play-circle:before { content: unquote("\"#{ $fa-var-play-circle }\""); }
.#{$fa-css-prefix}-playstation:before { content: unquote("\"#{ $fa-var-playstation }\""); }
.#{$fa-css-prefix}-plug:before { content: unquote("\"#{ $fa-var-plug }\""); }
.#{$fa-css-prefix}-plus:before { content: unquote("\"#{ $fa-var-plus }\""); }
.#{$fa-css-prefix}-plus-circle:before { content: unquote("\"#{ $fa-var-plus-circle }\""); }
.#{$fa-css-prefix}-plus-hexagon:before { content: unquote("\"#{ $fa-var-plus-hexagon }\""); }
.#{$fa-css-prefix}-plus-octagon:before { content: unquote("\"#{ $fa-var-plus-octagon }\""); }
.#{$fa-css-prefix}-plus-square:before { content: unquote("\"#{ $fa-var-plus-square }\""); }
.#{$fa-css-prefix}-podcast:before { content: unquote("\"#{ $fa-var-podcast }\""); }
.#{$fa-css-prefix}-poo:before { content: unquote("\"#{ $fa-var-poo }\""); }
.#{$fa-css-prefix}-portrait:before { content: unquote("\"#{ $fa-var-portrait }\""); }
.#{$fa-css-prefix}-pound-sign:before { content: unquote("\"#{ $fa-var-pound-sign }\""); }
.#{$fa-css-prefix}-power-off:before { content: unquote("\"#{ $fa-var-power-off }\""); }
.#{$fa-css-prefix}-print:before { content: unquote("\"#{ $fa-var-print }\""); }
.#{$fa-css-prefix}-product-hunt:before { content: unquote("\"#{ $fa-var-product-hunt }\""); }
.#{$fa-css-prefix}-pushed:before { content: unquote("\"#{ $fa-var-pushed }\""); }
.#{$fa-css-prefix}-puzzle-piece:before { content: unquote("\"#{ $fa-var-puzzle-piece }\""); }
.#{$fa-css-prefix}-python:before { content: unquote("\"#{ $fa-var-python }\""); }
.#{$fa-css-prefix}-qq:before { content: unquote("\"#{ $fa-var-qq }\""); }
.#{$fa-css-prefix}-qrcode:before { content: unquote("\"#{ $fa-var-qrcode }\""); }
.#{$fa-css-prefix}-question:before { content: unquote("\"#{ $fa-var-question }\""); }
.#{$fa-css-prefix}-question-circle:before { content: unquote("\"#{ $fa-var-question-circle }\""); }
.#{$fa-css-prefix}-question-square:before { content: unquote("\"#{ $fa-var-question-square }\""); }
.#{$fa-css-prefix}-quora:before { content: unquote("\"#{ $fa-var-quora }\""); }
.#{$fa-css-prefix}-quote-left:before { content: unquote("\"#{ $fa-var-quote-left }\""); }
.#{$fa-css-prefix}-quote-right:before { content: unquote("\"#{ $fa-var-quote-right }\""); }
.#{$fa-css-prefix}-random:before { content: unquote("\"#{ $fa-var-random }\""); }
.#{$fa-css-prefix}-ravelry:before { content: unquote("\"#{ $fa-var-ravelry }\""); }
.#{$fa-css-prefix}-react:before { content: unquote("\"#{ $fa-var-react }\""); }
.#{$fa-css-prefix}-rebel:before { content: unquote("\"#{ $fa-var-rebel }\""); }
.#{$fa-css-prefix}-rectangle-landscape:before { content: unquote("\"#{ $fa-var-rectangle-landscape }\""); }
.#{$fa-css-prefix}-rectangle-portrait:before { content: unquote("\"#{ $fa-var-rectangle-portrait }\""); }
.#{$fa-css-prefix}-rectangle-wide:before { content: unquote("\"#{ $fa-var-rectangle-wide }\""); }
.#{$fa-css-prefix}-recycle:before { content: unquote("\"#{ $fa-var-recycle }\""); }
.#{$fa-css-prefix}-red-river:before { content: unquote("\"#{ $fa-var-red-river }\""); }
.#{$fa-css-prefix}-reddit:before { content: unquote("\"#{ $fa-var-reddit }\""); }
.#{$fa-css-prefix}-reddit-alien:before { content: unquote("\"#{ $fa-var-reddit-alien }\""); }
.#{$fa-css-prefix}-reddit-square:before { content: unquote("\"#{ $fa-var-reddit-square }\""); }
.#{$fa-css-prefix}-redo:before { content: unquote("\"#{ $fa-var-redo }\""); }
.#{$fa-css-prefix}-redo-alt:before { content: unquote("\"#{ $fa-var-redo-alt }\""); }
.#{$fa-css-prefix}-registered:before { content: unquote("\"#{ $fa-var-registered }\""); }
.#{$fa-css-prefix}-rendact:before { content: unquote("\"#{ $fa-var-rendact }\""); }
.#{$fa-css-prefix}-renren:before { content: unquote("\"#{ $fa-var-renren }\""); }
.#{$fa-css-prefix}-repeat:before { content: unquote("\"#{ $fa-var-repeat }\""); }
.#{$fa-css-prefix}-repeat-1:before { content: unquote("\"#{ $fa-var-repeat-1 }\""); }
.#{$fa-css-prefix}-repeat-1-alt:before { content: unquote("\"#{ $fa-var-repeat-1-alt }\""); }
.#{$fa-css-prefix}-repeat-alt:before { content: unquote("\"#{ $fa-var-repeat-alt }\""); }
.#{$fa-css-prefix}-reply:before { content: unquote("\"#{ $fa-var-reply }\""); }
.#{$fa-css-prefix}-reply-all:before { content: unquote("\"#{ $fa-var-reply-all }\""); }
.#{$fa-css-prefix}-replyd:before { content: unquote("\"#{ $fa-var-replyd }\""); }
.#{$fa-css-prefix}-resolving:before { content: unquote("\"#{ $fa-var-resolving }\""); }
.#{$fa-css-prefix}-retweet:before { content: unquote("\"#{ $fa-var-retweet }\""); }
.#{$fa-css-prefix}-retweet-alt:before { content: unquote("\"#{ $fa-var-retweet-alt }\""); }
.#{$fa-css-prefix}-road:before { content: unquote("\"#{ $fa-var-road }\""); }
.#{$fa-css-prefix}-rocket:before { content: unquote("\"#{ $fa-var-rocket }\""); }
.#{$fa-css-prefix}-rocketchat:before { content: unquote("\"#{ $fa-var-rocketchat }\""); }
.#{$fa-css-prefix}-rockrms:before { content: unquote("\"#{ $fa-var-rockrms }\""); }
.#{$fa-css-prefix}-rss:before { content: unquote("\"#{ $fa-var-rss }\""); }
.#{$fa-css-prefix}-rss-square:before { content: unquote("\"#{ $fa-var-rss-square }\""); }
.#{$fa-css-prefix}-ruble-sign:before { content: unquote("\"#{ $fa-var-ruble-sign }\""); }
.#{$fa-css-prefix}-rupee-sign:before { content: unquote("\"#{ $fa-var-rupee-sign }\""); }
.#{$fa-css-prefix}-safari:before { content: unquote("\"#{ $fa-var-safari }\""); }
.#{$fa-css-prefix}-sass:before { content: unquote("\"#{ $fa-var-sass }\""); }
.#{$fa-css-prefix}-save:before { content: unquote("\"#{ $fa-var-save }\""); }
.#{$fa-css-prefix}-schlix:before { content: unquote("\"#{ $fa-var-schlix }\""); }
.#{$fa-css-prefix}-scribd:before { content: unquote("\"#{ $fa-var-scribd }\""); }
.#{$fa-css-prefix}-scrubber:before { content: unquote("\"#{ $fa-var-scrubber }\""); }
.#{$fa-css-prefix}-search:before { content: unquote("\"#{ $fa-var-search }\""); }
.#{$fa-css-prefix}-search-minus:before { content: unquote("\"#{ $fa-var-search-minus }\""); }
.#{$fa-css-prefix}-search-plus:before { content: unquote("\"#{ $fa-var-search-plus }\""); }
.#{$fa-css-prefix}-searchengin:before { content: unquote("\"#{ $fa-var-searchengin }\""); }
.#{$fa-css-prefix}-sellcast:before { content: unquote("\"#{ $fa-var-sellcast }\""); }
.#{$fa-css-prefix}-sellsy:before { content: unquote("\"#{ $fa-var-sellsy }\""); }
.#{$fa-css-prefix}-server:before { content: unquote("\"#{ $fa-var-server }\""); }
.#{$fa-css-prefix}-servicestack:before { content: unquote("\"#{ $fa-var-servicestack }\""); }
.#{$fa-css-prefix}-share:before { content: unquote("\"#{ $fa-var-share }\""); }
.#{$fa-css-prefix}-share-all:before { content: unquote("\"#{ $fa-var-share-all }\""); }
.#{$fa-css-prefix}-share-alt:before { content: unquote("\"#{ $fa-var-share-alt }\""); }
.#{$fa-css-prefix}-share-alt-square:before { content: unquote("\"#{ $fa-var-share-alt-square }\""); }
.#{$fa-css-prefix}-share-square:before { content: unquote("\"#{ $fa-var-share-square }\""); }
.#{$fa-css-prefix}-shekel-sign:before { content: unquote("\"#{ $fa-var-shekel-sign }\""); }
.#{$fa-css-prefix}-shield:before { content: unquote("\"#{ $fa-var-shield }\""); }
.#{$fa-css-prefix}-shield-alt:before { content: unquote("\"#{ $fa-var-shield-alt }\""); }
.#{$fa-css-prefix}-shield-check:before { content: unquote("\"#{ $fa-var-shield-check }\""); }
.#{$fa-css-prefix}-ship:before { content: unquote("\"#{ $fa-var-ship }\""); }
.#{$fa-css-prefix}-shirtsinbulk:before { content: unquote("\"#{ $fa-var-shirtsinbulk }\""); }
.#{$fa-css-prefix}-shopping-bag:before { content: unquote("\"#{ $fa-var-shopping-bag }\""); }
.#{$fa-css-prefix}-shopping-basket:before { content: unquote("\"#{ $fa-var-shopping-basket }\""); }
.#{$fa-css-prefix}-shopping-cart:before { content: unquote("\"#{ $fa-var-shopping-cart }\""); }
.#{$fa-css-prefix}-shower:before { content: unquote("\"#{ $fa-var-shower }\""); }
.#{$fa-css-prefix}-sign-in:before { content: unquote("\"#{ $fa-var-sign-in }\""); }
.#{$fa-css-prefix}-sign-in-alt:before { content: unquote("\"#{ $fa-var-sign-in-alt }\""); }
.#{$fa-css-prefix}-sign-language:before { content: unquote("\"#{ $fa-var-sign-language }\""); }
.#{$fa-css-prefix}-sign-out:before { content: unquote("\"#{ $fa-var-sign-out }\""); }
.#{$fa-css-prefix}-sign-out-alt:before { content: unquote("\"#{ $fa-var-sign-out-alt }\""); }
.#{$fa-css-prefix}-signal:before { content: unquote("\"#{ $fa-var-signal }\""); }
.#{$fa-css-prefix}-simplybuilt:before { content: unquote("\"#{ $fa-var-simplybuilt }\""); }
.#{$fa-css-prefix}-sistrix:before { content: unquote("\"#{ $fa-var-sistrix }\""); }
.#{$fa-css-prefix}-sitemap:before { content: unquote("\"#{ $fa-var-sitemap }\""); }
.#{$fa-css-prefix}-skyatlas:before { content: unquote("\"#{ $fa-var-skyatlas }\""); }
.#{$fa-css-prefix}-skype:before { content: unquote("\"#{ $fa-var-skype }\""); }
.#{$fa-css-prefix}-slack:before { content: unquote("\"#{ $fa-var-slack }\""); }
.#{$fa-css-prefix}-slack-hash:before { content: unquote("\"#{ $fa-var-slack-hash }\""); }
.#{$fa-css-prefix}-sliders-h:before { content: unquote("\"#{ $fa-var-sliders-h }\""); }
.#{$fa-css-prefix}-sliders-h-square:before { content: unquote("\"#{ $fa-var-sliders-h-square }\""); }
.#{$fa-css-prefix}-sliders-v:before { content: unquote("\"#{ $fa-var-sliders-v }\""); }
.#{$fa-css-prefix}-sliders-v-square:before { content: unquote("\"#{ $fa-var-sliders-v-square }\""); }
.#{$fa-css-prefix}-slideshare:before { content: unquote("\"#{ $fa-var-slideshare }\""); }
.#{$fa-css-prefix}-smile:before { content: unquote("\"#{ $fa-var-smile }\""); }
.#{$fa-css-prefix}-snapchat:before { content: unquote("\"#{ $fa-var-snapchat }\""); }
.#{$fa-css-prefix}-snapchat-ghost:before { content: unquote("\"#{ $fa-var-snapchat-ghost }\""); }
.#{$fa-css-prefix}-snapchat-square:before { content: unquote("\"#{ $fa-var-snapchat-square }\""); }
.#{$fa-css-prefix}-snowflake:before { content: unquote("\"#{ $fa-var-snowflake }\""); }
.#{$fa-css-prefix}-sort:before { content: unquote("\"#{ $fa-var-sort }\""); }
.#{$fa-css-prefix}-sort-alpha-down:before { content: unquote("\"#{ $fa-var-sort-alpha-down }\""); }
.#{$fa-css-prefix}-sort-alpha-up:before { content: unquote("\"#{ $fa-var-sort-alpha-up }\""); }
.#{$fa-css-prefix}-sort-amount-down:before { content: unquote("\"#{ $fa-var-sort-amount-down }\""); }
.#{$fa-css-prefix}-sort-amount-up:before { content: unquote("\"#{ $fa-var-sort-amount-up }\""); }
.#{$fa-css-prefix}-sort-down:before { content: unquote("\"#{ $fa-var-sort-down }\""); }
.#{$fa-css-prefix}-sort-numeric-down:before { content: unquote("\"#{ $fa-var-sort-numeric-down }\""); }
.#{$fa-css-prefix}-sort-numeric-up:before { content: unquote("\"#{ $fa-var-sort-numeric-up }\""); }
.#{$fa-css-prefix}-sort-up:before { content: unquote("\"#{ $fa-var-sort-up }\""); }
.#{$fa-css-prefix}-soundcloud:before { content: unquote("\"#{ $fa-var-soundcloud }\""); }
.#{$fa-css-prefix}-space-shuttle:before { content: unquote("\"#{ $fa-var-space-shuttle }\""); }
.#{$fa-css-prefix}-spade:before { content: unquote("\"#{ $fa-var-spade }\""); }
.#{$fa-css-prefix}-speakap:before { content: unquote("\"#{ $fa-var-speakap }\""); }
.#{$fa-css-prefix}-spinner:before { content: unquote("\"#{ $fa-var-spinner }\""); }
.#{$fa-css-prefix}-spinner-third:before { content: unquote("\"#{ $fa-var-spinner-third }\""); }
.#{$fa-css-prefix}-spotify:before { content: unquote("\"#{ $fa-var-spotify }\""); }
.#{$fa-css-prefix}-square:before { content: unquote("\"#{ $fa-var-square }\""); }
.#{$fa-css-prefix}-stack-exchange:before { content: unquote("\"#{ $fa-var-stack-exchange }\""); }
.#{$fa-css-prefix}-stack-overflow:before { content: unquote("\"#{ $fa-var-stack-overflow }\""); }
.#{$fa-css-prefix}-star:before { content: unquote("\"#{ $fa-var-star }\""); }
.#{$fa-css-prefix}-star-exclamation:before { content: unquote("\"#{ $fa-var-star-exclamation }\""); }
.#{$fa-css-prefix}-star-half:before { content: unquote("\"#{ $fa-var-star-half }\""); }
.#{$fa-css-prefix}-staylinked:before { content: unquote("\"#{ $fa-var-staylinked }\""); }
.#{$fa-css-prefix}-steam:before { content: unquote("\"#{ $fa-var-steam }\""); }
.#{$fa-css-prefix}-steam-square:before { content: unquote("\"#{ $fa-var-steam-square }\""); }
.#{$fa-css-prefix}-steam-symbol:before { content: unquote("\"#{ $fa-var-steam-symbol }\""); }
.#{$fa-css-prefix}-step-backward:before { content: unquote("\"#{ $fa-var-step-backward }\""); }
.#{$fa-css-prefix}-step-forward:before { content: unquote("\"#{ $fa-var-step-forward }\""); }
.#{$fa-css-prefix}-stethoscope:before { content: unquote("\"#{ $fa-var-stethoscope }\""); }
.#{$fa-css-prefix}-sticker-mule:before { content: unquote("\"#{ $fa-var-sticker-mule }\""); }
.#{$fa-css-prefix}-sticky-note:before { content: unquote("\"#{ $fa-var-sticky-note }\""); }
.#{$fa-css-prefix}-stop:before { content: unquote("\"#{ $fa-var-stop }\""); }
.#{$fa-css-prefix}-stop-circle:before { content: unquote("\"#{ $fa-var-stop-circle }\""); }
.#{$fa-css-prefix}-stopwatch:before { content: unquote("\"#{ $fa-var-stopwatch }\""); }
.#{$fa-css-prefix}-street-view:before { content: unquote("\"#{ $fa-var-street-view }\""); }
.#{$fa-css-prefix}-strikethrough:before { content: unquote("\"#{ $fa-var-strikethrough }\""); }
.#{$fa-css-prefix}-studiovinari:before { content: unquote("\"#{ $fa-var-studiovinari }\""); }
.#{$fa-css-prefix}-stumbleupon:before { content: unquote("\"#{ $fa-var-stumbleupon }\""); }
.#{$fa-css-prefix}-stumbleupon-circle:before { content: unquote("\"#{ $fa-var-stumbleupon-circle }\""); }
.#{$fa-css-prefix}-subscript:before { content: unquote("\"#{ $fa-var-subscript }\""); }
.#{$fa-css-prefix}-subway:before { content: unquote("\"#{ $fa-var-subway }\""); }
.#{$fa-css-prefix}-suitcase:before { content: unquote("\"#{ $fa-var-suitcase }\""); }
.#{$fa-css-prefix}-sun:before { content: unquote("\"#{ $fa-var-sun }\""); }
.#{$fa-css-prefix}-superpowers:before { content: unquote("\"#{ $fa-var-superpowers }\""); }
.#{$fa-css-prefix}-superscript:before { content: unquote("\"#{ $fa-var-superscript }\""); }
.#{$fa-css-prefix}-supple:before { content: unquote("\"#{ $fa-var-supple }\""); }
.#{$fa-css-prefix}-sync:before { content: unquote("\"#{ $fa-var-sync }\""); }
.#{$fa-css-prefix}-sync-alt:before { content: unquote("\"#{ $fa-var-sync-alt }\""); }
.#{$fa-css-prefix}-table:before { content: unquote("\"#{ $fa-var-table }\""); }
.#{$fa-css-prefix}-tablet:before { content: unquote("\"#{ $fa-var-tablet }\""); }
.#{$fa-css-prefix}-tablet-alt:before { content: unquote("\"#{ $fa-var-tablet-alt }\""); }
.#{$fa-css-prefix}-tablet-android:before { content: unquote("\"#{ $fa-var-tablet-android }\""); }
.#{$fa-css-prefix}-tablet-android-alt:before { content: unquote("\"#{ $fa-var-tablet-android-alt }\""); }
.#{$fa-css-prefix}-tachometer:before { content: unquote("\"#{ $fa-var-tachometer }\""); }
.#{$fa-css-prefix}-tachometer-alt:before { content: unquote("\"#{ $fa-var-tachometer-alt }\""); }
.#{$fa-css-prefix}-tag:before { content: unquote("\"#{ $fa-var-tag }\""); }
.#{$fa-css-prefix}-tags:before { content: unquote("\"#{ $fa-var-tags }\""); }
.#{$fa-css-prefix}-tasks:before { content: unquote("\"#{ $fa-var-tasks }\""); }
.#{$fa-css-prefix}-taxi:before { content: unquote("\"#{ $fa-var-taxi }\""); }
.#{$fa-css-prefix}-telegram:before { content: unquote("\"#{ $fa-var-telegram }\""); }
.#{$fa-css-prefix}-telegram-plane:before { content: unquote("\"#{ $fa-var-telegram-plane }\""); }
.#{$fa-css-prefix}-tencent-weibo:before { content: unquote("\"#{ $fa-var-tencent-weibo }\""); }
.#{$fa-css-prefix}-terminal:before { content: unquote("\"#{ $fa-var-terminal }\""); }
.#{$fa-css-prefix}-text-height:before { content: unquote("\"#{ $fa-var-text-height }\""); }
.#{$fa-css-prefix}-text-width:before { content: unquote("\"#{ $fa-var-text-width }\""); }
.#{$fa-css-prefix}-th:before { content: unquote("\"#{ $fa-var-th }\""); }
.#{$fa-css-prefix}-th-large:before { content: unquote("\"#{ $fa-var-th-large }\""); }
.#{$fa-css-prefix}-th-list:before { content: unquote("\"#{ $fa-var-th-list }\""); }
.#{$fa-css-prefix}-themeisle:before { content: unquote("\"#{ $fa-var-themeisle }\""); }
.#{$fa-css-prefix}-thermometer-empty:before { content: unquote("\"#{ $fa-var-thermometer-empty }\""); }
.#{$fa-css-prefix}-thermometer-full:before { content: unquote("\"#{ $fa-var-thermometer-full }\""); }
.#{$fa-css-prefix}-thermometer-half:before { content: unquote("\"#{ $fa-var-thermometer-half }\""); }
.#{$fa-css-prefix}-thermometer-quarter:before { content: unquote("\"#{ $fa-var-thermometer-quarter }\""); }
.#{$fa-css-prefix}-thermometer-three-quarters:before { content: unquote("\"#{ $fa-var-thermometer-three-quarters }\""); }
.#{$fa-css-prefix}-thumbs-down:before { content: unquote("\"#{ $fa-var-thumbs-down }\""); }
.#{$fa-css-prefix}-thumbs-up:before { content: unquote("\"#{ $fa-var-thumbs-up }\""); }
.#{$fa-css-prefix}-thumbtack:before { content: unquote("\"#{ $fa-var-thumbtack }\""); }
.#{$fa-css-prefix}-ticket:before { content: unquote("\"#{ $fa-var-ticket }\""); }
.#{$fa-css-prefix}-ticket-alt:before { content: unquote("\"#{ $fa-var-ticket-alt }\""); }
.#{$fa-css-prefix}-times:before { content: unquote("\"#{ $fa-var-times }\""); }
.#{$fa-css-prefix}-times-circle:before { content: unquote("\"#{ $fa-var-times-circle }\""); }
.#{$fa-css-prefix}-times-hexagon:before { content: unquote("\"#{ $fa-var-times-hexagon }\""); }
.#{$fa-css-prefix}-times-octagon:before { content: unquote("\"#{ $fa-var-times-octagon }\""); }
.#{$fa-css-prefix}-times-square:before { content: unquote("\"#{ $fa-var-times-square }\""); }
.#{$fa-css-prefix}-tint:before { content: unquote("\"#{ $fa-var-tint }\""); }
.#{$fa-css-prefix}-toggle-off:before { content: unquote("\"#{ $fa-var-toggle-off }\""); }
.#{$fa-css-prefix}-toggle-on:before { content: unquote("\"#{ $fa-var-toggle-on }\""); }
.#{$fa-css-prefix}-trademark:before { content: unquote("\"#{ $fa-var-trademark }\""); }
.#{$fa-css-prefix}-train:before { content: unquote("\"#{ $fa-var-train }\""); }
.#{$fa-css-prefix}-transgender:before { content: unquote("\"#{ $fa-var-transgender }\""); }
.#{$fa-css-prefix}-transgender-alt:before { content: unquote("\"#{ $fa-var-transgender-alt }\""); }
.#{$fa-css-prefix}-trash:before { content: unquote("\"#{ $fa-var-trash }\""); }
.#{$fa-css-prefix}-trash-alt:before { content: unquote("\"#{ $fa-var-trash-alt }\""); }
.#{$fa-css-prefix}-tree:before { content: unquote("\"#{ $fa-var-tree }\""); }
.#{$fa-css-prefix}-tree-alt:before { content: unquote("\"#{ $fa-var-tree-alt }\""); }
.#{$fa-css-prefix}-trello:before { content: unquote("\"#{ $fa-var-trello }\""); }
.#{$fa-css-prefix}-triangle:before { content: unquote("\"#{ $fa-var-triangle }\""); }
.#{$fa-css-prefix}-tripadvisor:before { content: unquote("\"#{ $fa-var-tripadvisor }\""); }
.#{$fa-css-prefix}-trophy:before { content: unquote("\"#{ $fa-var-trophy }\""); }
.#{$fa-css-prefix}-trophy-alt:before { content: unquote("\"#{ $fa-var-trophy-alt }\""); }
.#{$fa-css-prefix}-truck:before { content: unquote("\"#{ $fa-var-truck }\""); }
.#{$fa-css-prefix}-tty:before { content: unquote("\"#{ $fa-var-tty }\""); }
.#{$fa-css-prefix}-tumblr:before { content: unquote("\"#{ $fa-var-tumblr }\""); }
.#{$fa-css-prefix}-tumblr-square:before { content: unquote("\"#{ $fa-var-tumblr-square }\""); }
.#{$fa-css-prefix}-tv:before { content: unquote("\"#{ $fa-var-tv }\""); }
.#{$fa-css-prefix}-tv-retro:before { content: unquote("\"#{ $fa-var-tv-retro }\""); }
.#{$fa-css-prefix}-twitch:before { content: unquote("\"#{ $fa-var-twitch }\""); }
.#{$fa-css-prefix}-twitter:before { content: unquote("\"#{ $fa-var-twitter }\""); }
.#{$fa-css-prefix}-twitter-square:before { content: unquote("\"#{ $fa-var-twitter-square }\""); }
.#{$fa-css-prefix}-uber:before { content: unquote("\"#{ $fa-var-uber }\""); }
.#{$fa-css-prefix}-uikit:before { content: unquote("\"#{ $fa-var-uikit }\""); }
.#{$fa-css-prefix}-umbrella:before { content: unquote("\"#{ $fa-var-umbrella }\""); }
.#{$fa-css-prefix}-underline:before { content: unquote("\"#{ $fa-var-underline }\""); }
.#{$fa-css-prefix}-undo:before { content: unquote("\"#{ $fa-var-undo }\""); }
.#{$fa-css-prefix}-undo-alt:before { content: unquote("\"#{ $fa-var-undo-alt }\""); }
.#{$fa-css-prefix}-uniregistry:before { content: unquote("\"#{ $fa-var-uniregistry }\""); }
.#{$fa-css-prefix}-universal-access:before { content: unquote("\"#{ $fa-var-universal-access }\""); }
.#{$fa-css-prefix}-university:before { content: unquote("\"#{ $fa-var-university }\""); }
.#{$fa-css-prefix}-unlink:before { content: unquote("\"#{ $fa-var-unlink }\""); }
.#{$fa-css-prefix}-unlock:before { content: unquote("\"#{ $fa-var-unlock }\""); }
.#{$fa-css-prefix}-unlock-alt:before { content: unquote("\"#{ $fa-var-unlock-alt }\""); }
.#{$fa-css-prefix}-untappd:before { content: unquote("\"#{ $fa-var-untappd }\""); }
.#{$fa-css-prefix}-upload:before { content: unquote("\"#{ $fa-var-upload }\""); }
.#{$fa-css-prefix}-usb:before { content: unquote("\"#{ $fa-var-usb }\""); }
.#{$fa-css-prefix}-usd-circle:before { content: unquote("\"#{ $fa-var-usd-circle }\""); }
.#{$fa-css-prefix}-usd-square:before { content: unquote("\"#{ $fa-var-usd-square }\""); }
.#{$fa-css-prefix}-user:before { content: unquote("\"#{ $fa-var-user }\""); }
.#{$fa-css-prefix}-user-alt:before { content: unquote("\"#{ $fa-var-user-alt }\""); }
.#{$fa-css-prefix}-user-circle:before { content: unquote("\"#{ $fa-var-user-circle }\""); }
.#{$fa-css-prefix}-user-md:before { content: unquote("\"#{ $fa-var-user-md }\""); }
.#{$fa-css-prefix}-user-plus:before { content: unquote("\"#{ $fa-var-user-plus }\""); }
.#{$fa-css-prefix}-user-secret:before { content: unquote("\"#{ $fa-var-user-secret }\""); }
.#{$fa-css-prefix}-user-times:before { content: unquote("\"#{ $fa-var-user-times }\""); }
.#{$fa-css-prefix}-users:before { content: unquote("\"#{ $fa-var-users }\""); }
.#{$fa-css-prefix}-ussunnah:before { content: unquote("\"#{ $fa-var-ussunnah }\""); }
.#{$fa-css-prefix}-utensil-fork:before { content: unquote("\"#{ $fa-var-utensil-fork }\""); }
.#{$fa-css-prefix}-utensil-knife:before { content: unquote("\"#{ $fa-var-utensil-knife }\""); }
.#{$fa-css-prefix}-utensil-spoon:before { content: unquote("\"#{ $fa-var-utensil-spoon }\""); }
.#{$fa-css-prefix}-utensils:before { content: unquote("\"#{ $fa-var-utensils }\""); }
.#{$fa-css-prefix}-utensils-alt:before { content: unquote("\"#{ $fa-var-utensils-alt }\""); }
.#{$fa-css-prefix}-vaadin:before { content: unquote("\"#{ $fa-var-vaadin }\""); }
.#{$fa-css-prefix}-venus:before { content: unquote("\"#{ $fa-var-venus }\""); }
.#{$fa-css-prefix}-venus-double:before { content: unquote("\"#{ $fa-var-venus-double }\""); }
.#{$fa-css-prefix}-venus-mars:before { content: unquote("\"#{ $fa-var-venus-mars }\""); }
.#{$fa-css-prefix}-viacoin:before { content: unquote("\"#{ $fa-var-viacoin }\""); }
.#{$fa-css-prefix}-viadeo:before { content: unquote("\"#{ $fa-var-viadeo }\""); }
.#{$fa-css-prefix}-viadeo-square:before { content: unquote("\"#{ $fa-var-viadeo-square }\""); }
.#{$fa-css-prefix}-viber:before { content: unquote("\"#{ $fa-var-viber }\""); }
.#{$fa-css-prefix}-video:before { content: unquote("\"#{ $fa-var-video }\""); }
.#{$fa-css-prefix}-vimeo:before { content: unquote("\"#{ $fa-var-vimeo }\""); }
.#{$fa-css-prefix}-vimeo-square:before { content: unquote("\"#{ $fa-var-vimeo-square }\""); }
.#{$fa-css-prefix}-vimeo-v:before { content: unquote("\"#{ $fa-var-vimeo-v }\""); }
.#{$fa-css-prefix}-vine:before { content: unquote("\"#{ $fa-var-vine }\""); }
.#{$fa-css-prefix}-vk:before { content: unquote("\"#{ $fa-var-vk }\""); }
.#{$fa-css-prefix}-vnv:before { content: unquote("\"#{ $fa-var-vnv }\""); }
.#{$fa-css-prefix}-volume-down:before { content: unquote("\"#{ $fa-var-volume-down }\""); }
.#{$fa-css-prefix}-volume-mute:before { content: unquote("\"#{ $fa-var-volume-mute }\""); }
.#{$fa-css-prefix}-volume-off:before { content: unquote("\"#{ $fa-var-volume-off }\""); }
.#{$fa-css-prefix}-volume-up:before { content: unquote("\"#{ $fa-var-volume-up }\""); }
.#{$fa-css-prefix}-vuejs:before { content: unquote("\"#{ $fa-var-vuejs }\""); }
.#{$fa-css-prefix}-watch:before { content: unquote("\"#{ $fa-var-watch }\""); }
.#{$fa-css-prefix}-weibo:before { content: unquote("\"#{ $fa-var-weibo }\""); }
.#{$fa-css-prefix}-weixin:before { content: unquote("\"#{ $fa-var-weixin }\""); }
.#{$fa-css-prefix}-whatsapp:before { content: unquote("\"#{ $fa-var-whatsapp }\""); }
.#{$fa-css-prefix}-whatsapp-square:before { content: unquote("\"#{ $fa-var-whatsapp-square }\""); }
.#{$fa-css-prefix}-wheelchair:before { content: unquote("\"#{ $fa-var-wheelchair }\""); }
.#{$fa-css-prefix}-whmcs:before { content: unquote("\"#{ $fa-var-whmcs }\""); }
.#{$fa-css-prefix}-wifi:before { content: unquote("\"#{ $fa-var-wifi }\""); }
.#{$fa-css-prefix}-wikipedia-w:before { content: unquote("\"#{ $fa-var-wikipedia-w }\""); }
.#{$fa-css-prefix}-window:before { content: unquote("\"#{ $fa-var-window }\""); }
.#{$fa-css-prefix}-window-alt:before { content: unquote("\"#{ $fa-var-window-alt }\""); }
.#{$fa-css-prefix}-window-close:before { content: unquote("\"#{ $fa-var-window-close }\""); }
.#{$fa-css-prefix}-window-maximize:before { content: unquote("\"#{ $fa-var-window-maximize }\""); }
.#{$fa-css-prefix}-window-minimize:before { content: unquote("\"#{ $fa-var-window-minimize }\""); }
.#{$fa-css-prefix}-window-restore:before { content: unquote("\"#{ $fa-var-window-restore }\""); }
.#{$fa-css-prefix}-windows:before { content: unquote("\"#{ $fa-var-windows }\""); }
.#{$fa-css-prefix}-won-sign:before { content: unquote("\"#{ $fa-var-won-sign }\""); }
.#{$fa-css-prefix}-wordpress:before { content: unquote("\"#{ $fa-var-wordpress }\""); }
.#{$fa-css-prefix}-wordpress-simple:before { content: unquote("\"#{ $fa-var-wordpress-simple }\""); }
.#{$fa-css-prefix}-wpbeginner:before { content: unquote("\"#{ $fa-var-wpbeginner }\""); }
.#{$fa-css-prefix}-wpexplorer:before { content: unquote("\"#{ $fa-var-wpexplorer }\""); }
.#{$fa-css-prefix}-wpforms:before { content: unquote("\"#{ $fa-var-wpforms }\""); }
.#{$fa-css-prefix}-wrench:before { content: unquote("\"#{ $fa-var-wrench }\""); }
.#{$fa-css-prefix}-xbox:before { content: unquote("\"#{ $fa-var-xbox }\""); }
.#{$fa-css-prefix}-xing:before { content: unquote("\"#{ $fa-var-xing }\""); }
.#{$fa-css-prefix}-xing-square:before { content: unquote("\"#{ $fa-var-xing-square }\""); }
.#{$fa-css-prefix}-y-combinator:before { content: unquote("\"#{ $fa-var-y-combinator }\""); }
.#{$fa-css-prefix}-yahoo:before { content: unquote("\"#{ $fa-var-yahoo }\""); }
.#{$fa-css-prefix}-yandex:before { content: unquote("\"#{ $fa-var-yandex }\""); }
.#{$fa-css-prefix}-yandex-international:before { content: unquote("\"#{ $fa-var-yandex-international }\""); }
.#{$fa-css-prefix}-yelp:before { content: unquote("\"#{ $fa-var-yelp }\""); }
.#{$fa-css-prefix}-yen-sign:before { content: unquote("\"#{ $fa-var-yen-sign }\""); }
.#{$fa-css-prefix}-yoast:before { content: unquote("\"#{ $fa-var-yoast }\""); }
.#{$fa-css-prefix}-youtube:before { content: unquote("\"#{ $fa-var-youtube }\""); }
