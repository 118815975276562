.article-cards
    display: flex
    flex-flow: row wrap
    justify-content: space-between
    margin: $core-margin-medium 0
    margin-left: -$core-margin-full
    &::after
        content: ""
        flex: auto

.article-card
    width: 100%
    margin-bottom: $core-margin-full * 1.5
    padding-left: $core-margin-full
    +mq($from: tablet)
        max-width: 50%
    +mq($from: desktop)
        max-width: 33%
    &__thumbnail
        .aspect-ratio-container
            +aspect-ratio(375,220)
        img
            width: 100%
            height: auto
            border-radius: $radius

    &__title
        font-size: 20px
        padding: $core-margin-small 0
        +mq($from: tablet)
            padding: 0

    &__content
        margin: $core-margin-medium 0
        color: $dark-gray
        line-height: 130%

    &__lead
        margin: $core-margin-x-small 0

    &__read-more
        display: none
        +mq($from: tablet)
            box-shadow: 0 2px 4px rgba(50, 51, 56, .11), 0 1px 3px rgba(0, 0, 0, .15)
            background-image: linear-gradient(to bottom left, lighten(#ff021e, 10), lighten(#f2017e, 10))
            color: #ffffff
            padding: 1px 6px
            font-weight: 600
            border-radius: $radius


    &__date
        color: $gray
        text-transform: uppercase
        font-size: 12px
