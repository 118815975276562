#bio_ep_bg
    display: none
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: $black
    opacity: 0.4
    z-index: map-get($z-index, exit-popup-background)

.goodbye-popup
    position: fixed
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    z-index: map-get($z-index, exit-popup)
    width: 750px
    height: 450px
    transform: translate(-50%, -50%)
    display: none

    &__logo
        position: absolute
        left: 50%
        transform: translate(-50%, -30%)
        z-index: 1
        width: 150px
        height: auto
        background: #ffffff
        box-shadow: 0 1px 4px rgba(0,0,0,.15)
        padding: $core-margin-small $core-margin-medium
        border-radius: $radius-large

    .goodbye-popup-container
        display: flex
        overflow: hidden
        border-radius: 25px
        background: #ffffff
        box-shadow: 0 22px 50px rgba(0,0,0, .20), 0 8px 18px rgba(0,0,0,.09), 0 0px 3px rgba(0,0,0,0.09)

    &__content
        display: flex
        flex-direction: column
        justify-content: center
        padding: 0 ($core-margin-large * 2)

    &__image
        flex-shrink: 0
        width: 375px
        height: 450px
        img
            position: relative

    &__wait
        color: $brand-color
        font-size: 42px
        font-weight: 700
        margin-bottom: $core-margin-medium
        letter-spacing: -1px

    &__it-only-takes
        color: $brand-color
        font-size: 20px
        margin-bottom: $core-margin-large * 2

    &__text
        font-size: 24px
        color: $dark-gray
        line-height: 110%
        font-weight: 600
        margin-bottom: $core-margin-x-small
        letter-spacing: -0.2px

    &__bullets
        color: $brand-color
        margin-bottom: $core-margin-large * 2

    &__buttons
        color: $brand-color
        margin-bottom: $core-margin-full

        a
            margin-bottom: $core-margin-small

        .button--primary
            font-size: 19px
            letter-spacing: -0.5px

        .button--ghost
            background: $ultra-light-gray
            box-shadow: none
            border: 1px solid $extra-light-gray
            font-size: 12px
            height: auto
            padding: $core-margin-x-small $core-margin-full
            font-weight: 400
            display: table
            margin: 0 auto
            width: auto

    #bio_ep_close
        $popup-close-button-size: 30px
        position: absolute
        z-index: 1
        top: calc($popup-close-button-size/2)
        right: calc($popup-close-button-size/2)
        width: $popup-close-button-size
        height: $popup-close-button-size
        display: flex
        align-items: center
        justify-content: center
        border-radius: 50%
        //box-shadow: 0 1px 4px rgba(0,0,0,.15)
        //background: $light-gray
        color: #ffffff
        font-size: 22px
        font-weight: 700
        cursor: pointer
