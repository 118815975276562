/* -----------------------------------
 *   Tables
 * ----------------------------------- */




// --- Placeholders ---

%table
    width: 100%
    border-collapse: collapse
    border-spacing: 0

%th-td
    padding: calc($core-margin-full / 4)
    text-align: left
    font-weight: normal
    vertical-align: middle




// --- Styling ---

table
    @extend %table

th
    @extend %th-td
    //font-weight: bold

td
    @extend %th-td

[colspan]
    text-align: center

[colspan="1"]
    text-align: center

[rowspan]
    vertical-align: middle

[rowspan="1"]
    vertical-align: top

.t5
    width: 5%
.t10
    width: 10%
.t15
    width: 15%
.t20
    width: 20%
.t25
    width: 25%
.t30
    width: 30%
.t35
    width: 35%
.t40
    width: 40%
.t45
    width: 45%
.t50
    width: 50%
.t55
    width: 55%
.t60
    width: 60%
.t65
    width: 65%
.t70
    width: 70%
.t75
    width: 75%
.t80
    width: 80%
.t85
    width: 85%
.t90
    width: 90%
.t95
    width: 95%


// If numerical place text to the right
.numerical
    text-align: right

.table--grid
    td
        border: 1px solid #eeeff1


table
    @extend %table
