.infobar
    background: lighten(#e5344a, 38)
    color: #e5344a
    padding: $core-margin-medium 0
    font-weight: 600
    +mq($from: desktop)
        text-align: center
    &__inner
        @extend %site-max-width
        +mq($until: desktop)
            padding: 0 $core-margin-medium
    &__date
        color: lighten(#e5344a, 15)
        display: inline-block
        margin-right: $core-margin-small
    i.fal
        margin-right: $core-margin-small
        color: lighten(#e5344a, 15)
