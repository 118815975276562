html
    font:
        family: $font-family

body.no-scroll
    position: fixed
    overflow: hidden

img
    display: block
    max-width: 100%
    height: auto

b, strong
    font-weight: 700
