// Placeholder for text input types
%global-input
    appearance: none
    outline: 0
    width: 100%
    display: block
    position: relative
    border: $border
    border-radius: $radius
    transition: all 150ms ease-out
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    text-rendering: optimizeLegibility
    height: 36px
    padding: 0 $core-margin-medium
    font:
        family: $font-family
        size: 16px
    +mq($from: tablet)
        width: 100%
        font-size: 14px

    &:hover
        border-color: lighten(#f2017e, 40)

    &:focus
        border-color: #f2017e
        color: #f2017e
        font-weight: 600

    &.disabled, &[readonly=readonly]
        background-color: $ultra-light-gray
        color: $gray

    &::-ms-clear
        display: none

    &::placeholder
        color: $light-gray
        font-weight: 400

// Text inputs
input[type=text],
input[type=password],
input[type=tel],
input[type=number],
input[type=email]
    @extend %global-input


// Textarea
textarea
    @extend  %global-input


.input-search-wrapper
    display: flex
    align-items: center
    &:before
        content: "\f002"
        font-family: "Font Awesome 5 Light"
        font-size: 14px
        z-index: 1
        color: inherit
        position: absolute
        top: 8px
        display: flex
        align-items: center
        left: 13px
        opacity: 0.3
    input
        padding-left: $core-margin-full + $core-margin-medium
