.footer-wrapper
    display: block
    border-top: 1px solid #eeeeee
    padding-top: $core-margin-full * 2
    margin: 0 auto $core-margin-large
    +mq($from: tablet)
        margin: 0 auto $core-margin-full * 3

.footer-container
    padding: 0 $core-margin-full
    margin: 0 auto
    display: block
    transition: opacity 0.2s ease-out
    +mq($from: desktop)
        opacity: 0.5
        padding: 0
        max-width: 800px
        &:hover
            opacity: 0.8

.footer-columns-wrapper
    display: flex
    flex-direction: column
    +mq($from: tablet)
        flex-direction: row
        justify-content: space-between

.footer-column
    width: 100%
    margin-bottom: $core-margin-full
    +mq($from: tablet)
        margin-bottom: 0
        width: 27%
    &--logo
        display: flex
        align-items: center
        +mq($from: tablet)
            display: block
            width: 13%
    a
        color: $dark-gray
        &:hover
            color: $brand-color

    &__links
        +mq($until: tablet)
            display: flex
            flex-wrap: wrap
            margin: $core-margin-medium (-$core-margin-medium)
            padding: $core-margin-medium 0
            border-top: 1px solid #eeeeee
            border-bottom: 1px solid #eeeeee
            li
                width: 50%
                display: block

            a,
            b
                display: block
                padding: $core-margin-medium


.footer-logo-bookmark
    width: 52px
    height: auto
    margin-right: $core-margin-full
    +mq($from: tablet)
        margin-right: 0
        margin-bottom: $core-margin-large

.footer-social-icons
    a
        color: $brand-color
        display: inline-block
    i
        font-size: 30px
        margin-right: $core-margin-full
        +mq($from: tablet)
            margin-right: $core-margin-small
            font-size: 22px
    span
        font-size: 0

.footer-badges-wrapper
    +mq($until: tablet)
        display: flex
        justify-content: space-between
        align-items: center
        margin-top: $core-margin-medium
        border-bottom: 1px solid #eeeeee
        padding-bottom: $core-margin-full * 2

.footer-badge
    margin-top: $core-margin-medium
    &__hipaa
        width: 100px
        overflow: hidden
        img
            width: 100%
    &__rheumatism
        width: 130px
        overflow: hidden
        img
            width: 100%

.copyright-wrapper
    color: $gray
    display: flex
    justify-content: center
    align-items: center
    margin-top: $core-margin-full
    margin-bottom: $core-margin-full * 2
    +mq($from: tablet)
        margin-bottom: 0

.go-to-top
    display: none
    +mq($until: tablet)
        display: flex
        margin: $core-margin-full 0 $core-margin-full*2
        i
            padding: $core-margin-small
            font-size: 14px
