// Buttons
.button
    position: relative
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    width: 100%
    border: 0
    margin: 0
    padding: 0 $core-margin-large
    appearance: none
    box-shadow: 0 2px 4px rgba(50, 51, 56, .11), 0 1px 3px rgba(0, 0, 0, .15)
    outline: 0
    transition: all 100ms ease
    letter-spacing: -0.15px
    text-align: center
    user-select: none
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    text-rendering: optimizeLegibility
    border-radius: $radius
    background-image: linear-gradient(#ffffff, #f8f8f8)
    color: #f2017e
    height: 38px
    font:
        size: 16px
        weight: 600

    &:hover
        transform: translateY(-1px)
        box-shadow: 0 4px 9px rgba(50, 51, 56, .11), 0 3px 6px rgba(0,0,0,.08)
        color: #f2017e
    &:active
        transform: translateY(1px)
        box-shadow: 0 1px 3px rgba(50, 51, 56, .11), 0 1px 2px rgba(0,0,0,.08)
        color: #f2017e
    &:focus
        // Focus styling
    &:visited
        // Visited styling

    &--bordered
        border: 1px solid lighten(#f2017e, 10)

    &--primary
        background-image: linear-gradient(to bottom left, lighten(#ff021e, 10), lighten(#f2017e, 10))
        color: #ffffff

        &:hover,
        &:active
            color: #ffffff
