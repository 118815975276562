// https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: calc(($height / $width) * 100%);
        background: $ultra-light-gray;
        border-radius: $radius;
    }
    .aspect-ratio-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

img:not([src]) {
    visibility: hidden;
}

img[data-src],
img[data-srcset] {
    display: block;
    min-height: 1px;
}

.aspect-ratio-content {
    opacity: 0;
    transition: all 0.6s;
    &.loaded {
        opacity: 1;
    }
}
